import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import img from '../../Images/analyticsdashbordimage.png'
import img2 from '../../Images/Vector (26).png'
import img3 from '../../Images/Vector (27).png'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import arrowup from '../../Images/expand_less.png'
import img5 from '../../Images/Group (8).png'
import img6 from '../../Images/Vector (28).png'
import img7 from '../../Images/Vector (29).png'
import img8 from '../../Images/calendar.png'
import arrowup2 from '../../Images/arrow-up.png'
import arrowdown from '../../Images/arrow-down.png'
import logo from '../../Images/Neoo logo PWA 1.png'
import logo2 from '../../Images/1logo 2.png'
import logo3 from '../../Images/4logo 2.png'
import user from '../../Images/user.png'
import { fetchAnalyticsBasicDetails, fetchAnalyticsFollowers, fetchAnalyticsProfileReport } from '../../Redux/Slice/AnalyticsSlice';
import StarRatings from 'react-star-ratings';
import { formatDateAgo } from '../../utils/Helper';
import { SetDisplayUserData } from '../../Redux/Slice/PostSlice';
import defaultUserImage from '../../Images/logootherblm.png'

const AnalyticsProfile = () => {
    const { UserProfileID } = useSelector((state) => state.app);
    const { AnalyticsBasicDetails, AnalyticsFollowers, AnalyticsStatus, AnalyticsProfileReport } = useSelector((state) => state.analytics);
    const [dropdown, setdropdown] = useState(false)
    const [dropdown2, setdropdown2] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const colordata = useContext(Colorcontext);
    const [filteredFollowers, setFilteredFollowers] = useState(AnalyticsProfileReport.allFollowersData || [])
    const [durationFilterType, setDurationFilterType] = useState("all")

    console.log("UYT33",filteredFollowers);
    

    const dispatch = useDispatch()
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };


    }, []);

    useEffect(() => {
        dispatch(fetchAnalyticsBasicDetails())
        dispatch(fetchAnalyticsFollowers())
        dispatch(fetchAnalyticsProfileReport())
    }, [])

    useEffect(() => {
        setFilteredFollowers(AnalyticsProfileReport.allFollowersData)
    }, [AnalyticsProfileReport.allFollowersData])

    useEffect(() => {
        const today = new Date();
        if (durationFilterType === "today") {
            // Format today's date to YYYY-MM-DD
            const todayString = today.toISOString().split("T")[0];
            setFilteredFollowers(
                [...AnalyticsProfileReport.allFollowersData]
                    .filter((item) => {
                        // Extract only the date part from createdOn
                        const createdDate = item.createdOn.split("T")[0];
                        return createdDate === todayString;
                    })
            )
        } else if (durationFilterType === "week") {
            // Get the first day of the current week (Sunday)
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay());
            startOfWeek.setHours(0, 0, 0, 0); // Set to start of the day

            // Get the last day of the current week (Saturday)
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            endOfWeek.setHours(23, 59, 59, 999); // Set to end of the day

            setFilteredFollowers(
                [...AnalyticsProfileReport.allFollowersData]
                    .filter((item) => {
                        const createdDate = new Date(item.createdOn);
                        return createdDate >= startOfWeek && createdDate <= endOfWeek;
                    })
                // .slice(0, itemsToShow)
            )
        } else if (durationFilterType === "month") {
            const currentMonth = today.getMonth(); // 0-based index (0 = January)
            const currentYear = today.getFullYear();

            setFilteredFollowers(
                [...AnalyticsProfileReport.allFollowersData]
                    .filter((item) => {
                        const createdDate = new Date(item.createdOn);
                        return (
                            createdDate.getMonth() === currentMonth && createdDate.getFullYear() === currentYear
                        );
                    })
            )
        } else {
            setFilteredFollowers(
                [...AnalyticsProfileReport.allFollowersData]
            )
        }

    }, [durationFilterType])

    const navigate = useNavigate()
    const [singleData, setSingleData] = useState()

    const handleBusinessPageView = (e, item) => {
        console.log("HYT110", item.companyName);

        e.preventDefault()
        setSingleData(item)
        dispatch(SetDisplayUserData([item]))
        navigate(`/businesspage/${item?.companyName?.toLowerCase().replace(/\s+/g, "_")}`)
        // navigate(`/MemberOtherSavedProfile/${singleData?.userName?.trim()}`)
    }

    return (
        <>
            <section className='membermastersection' style={{
                padding: '10px 0px'
            }}>
                <div className="cust_container">
                    <div className='membermastersectionbg member_section_new_class' style={{

                        background: 'transparent',
                    }}>
                        <div className='left_analtics_div_wrapper'>
                            <div className='left_analtics_container'>
                                <div className='left_analtics_logo'>
                                    <div className='analytics_img_div'>
                                        <img src={img} />

                                    </div>
                                    <p>Analytics</p>
                                </div>
                                <div className='nav_link_wrapper'>
                                    <NavLink to={'/analytics-report'} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img2} />
                                        </div>
                                        <p>Overview</p>
                                    </NavLink>
                                    <NavLink to={'/analytics'} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>BLM Page</p>
                                    </NavLink>
                                    <NavLink to={'/analytics-profile'} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>Profile</p>
                                    </NavLink>
                                </div>
                                {/* <div className='nav_link_wrapper'>
                                    <div onClick={(() => setdropdown(!dropdown))} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>BLM page</p>

                                        <img src={arrowup} style={{
                                            width: '22px',
                                            height: '22px',
                                            marginTop: '7px'
                                        }} />
                                    </div>
                                    {dropdown && <div className='blm_page_dropdown_div'>
                                        <p>Followers</p>
                                        <p>Public</p>
                                    </div>}

                                    <div onClick={(() => setdropdown2(!dropdown2))} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>Profile</p>

                                        <img src={arrowup} style={{
                                            width: '22px',
                                            height: '22px',
                                            marginTop: '7px'
                                        }} />
                                    </div>

                                    {dropdown2 && <div className='blm_page_dropdown_div'>
                                        <p>Members</p>
                                        <p>Non Members</p>
                                    </div>}
                                </div> */}
                            </div>
                            {/* <div className='export_div_wrapper'>
                                <div className='export_div'>
                                    <div className='export_img'>
                                        <img src={img5} />
                                    </div>
                                    <p>Export</p>
                                </div>
                                <div className='lock_img_div'>
                                    <img src={img6} />
                                </div>
                            </div> */}
                        </div>
                        <div className='right_analtics_div'>
                            <div className='right_analtics_div_container'>
                                <div className='overview_wrapper'>
                                    <p>Overview</p>
                                    {
                                        showFilter &&
                                        <div className='filter_box_wrapper'>
                                            <div className='filter_box'>
                                                <img src={img7} />
                                                <p>2</p>
                                            </div>
                                            <div className='filter_box'>
                                                <img src={img8} />
                                                <span>Jan 1, 2024 - Dec 31, 2024</span>
                                            </div>
                                        </div>
                                    }


                                </div>
                                <div className='enquires_wrapper'>
                                    <div className='enquire_div_1'>
                                        <p>Total Members</p>
                                        <h3>{AnalyticsProfileReport?.TotalMembersCount || 0}</h3>
                                    </div>
                                    <div className='enquire_div_1'>
                                        <p>Total Non members</p>
                                        <h3>{AnalyticsProfileReport?.TotalNonMembersCount || 0}</h3>
                                    </div>
                                    <div className='enquire_div_1'>
                                        <p>Profile Likes</p>
                                        <h3>{AnalyticsProfileReport?.TotalLikeCount || 0}</h3>
                                    </div>
                                    <div className='enquire_div_1'>
                                        <p>Profile Rating</p>
                                        <h3>{AnalyticsProfileReport?.totalProfileAvgRating || 0}</h3>
                                    </div>
                                </div>
                                <div className='right_box_wrapper' style={{display:"none"}}>
                                    <div className='right_box_div'>
                                        <p>Page Rating</p>
                                        <div className='star_wrapper'>
                                            <span>{AnalyticsBasicDetails?.totalPageAvgRating?.toFixed(1)}</span>
                                            <i class="fa-solid fa-star text-warning"></i>
                                            <i class="fa-solid fa-star text-warning"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <StarRatings
                                                rating={AnalyticsBasicDetails?.totalPageAvgRating || 0}
                                                starRatedColor="#ffc107"
                                                starEmptyColor="#212529"
                                                numberOfStars={5}
                                                name="UserProfileRating"
                                                starDimension="22px"
                                                starSpacing="3px"
                                                className="fa-solid fa-star"
                                            />
                                        </div>

                                    </div>

                                    <div className='right_box_div'>
                                        <p>Page Followers</p>
                                        <div className='star_wrapper2'>
                                            <span>{AnalyticsBasicDetails?.totalPageFollowerCount || 0}</span>
                                            {/* <h6>
                                                <img src={arrowup2} />
                                                26%
                                            </h6> */}
                                        </div>
                                    </div>

                                    <div className='right_box_div'>
                                        <p>Impressions</p>
                                        <div className='star_wrapper3'>
                                            <span>{AnalyticsBasicDetails?.totalImpressionCount || 0}</span>
                                            {/* <h6>
                                                <img src={arrowdown} />
                                                4%
                                            </h6> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='right_analtics_table_wrapper'>
                                <div className='all_actives_wrapper'>
                                    <h2>All Activities</h2>
                                    <div className='select_boxes'>
                                        {/* <select>
                                            <option value={'blmpage'}>BLM Page</option>
                                            <option value={'profile'}>Profile</option>
                                        </select>

                                        <select>
                                            <option value={'follow'}>Follower</option>
                                            <option value={'like'}>Liker</option>
                                            <option value={'rating'}>Reviewer</option>
                                        </select> */}

                                        <select
                                            onChange={(e) => { setDurationFilterType(e.target.value) }}
                                        >
                                            <option value={'all'}>Show all</option>
                                            <option value={'today'}>Today</option>
                                            <option value={'week'}>This Week</option>
                                            <option value={'month'}>This Month</option>
                                        </select>
                                    </div>


                                </div>
                                <div className='right_table_content_wrapper'>
                                    <table>
                                        <tbody>
                                            {filteredFollowers.map((e, i) => {
                                                return (
                                                    <>
                                                        <tr style={{
                                                            borderBottom: ' 1px solid rgba(195, 195, 195, 1)'
                                                        }}>
                                                            <td className='table_data_1'>
                                                                <div className='company_logo_details_Wrappper'
                                                                    style={{
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={(event) => handleBusinessPageView(event, e?.visitedByData)}
                                                                >
                                                                    <div className='logo_img656'>
                                                                        <img src={e.visitedByData.businessProfilePic || defaultUserImage} title={e?.visitedByData?.companyName} />
                                                                    </div>
                                                                    <div className='logo_details656'>
                                                                        <p>{e?.visitedByData?.companyName}</p>
                                                                        <span>{e?.visitedByData?.companyTypeName}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='table_data_2'>
                                                                Visited You
                                                            </td>
                                                            <td className='table_data_3'>
                                                                {formatDateAgo(e?.createdOn)}
                                                            </td>
                                                            <td className='table_data_4'>
                                                                <div>
                                                                    <img src={user} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AnalyticsProfile
