import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import HttpClient from "../utils/HttpClient";

const AddBusinessServiceModal = ({ closemodal,ids, services, setServices, updateForm,data }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [addService, setAddService] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  console.log("LIMN10X", services);


  const submit = (e) => {
    e.preventDefault()
    // const updatedServices =selectedOptions.map((item) => ({
    //   _id: item.value,
    //   name: item.label,
    // }))
    const flatSelectedOptions = selectedOptions.map((item) => item.value)
    const flatSelectedOptionsName = selectedOptions.map((item) => item.label)

    // const updatedServices = [...services, ...flatSelectedOptions];
    const updatedServices = [...services, ...flatSelectedOptionsName];
    const updatedServicesIds = [...ids, ...flatSelectedOptions];
    // const updatedServices = tempServices.map((item) => item.value)
    console.log("selectedOptions14", flatSelectedOptionsName)
    // return


    setServices(updatedServices);
    // setServices(selectedOptions);
    selectedOptions?.length > 0 && updateForm("businessProductCat", updatedServices);
    selectedOptions?.length > 0 && updateForm("businessProductCatId", updatedServicesIds);
    // updateForm("businessProductCat", []);
    // updateForm("businessProductCatId", []);
    
    // setAddService("");
    setSelectedOptions([])
    closemodal(false);
  };

  console.log("UserProfileID21", UserProfileID?.businessProductCat, selectedOptions);

  const [serviceOptions, setServiceOptions] = useState([])

  const getCompanyTypeWiseServices = async (id) => {
    try {
      const res = await HttpClient.requestData(
        `companyType-wise-servicess/${id}`,
        "GET"
      );

      if (res?.status) {
        console.log("RES46E", res.data, UserProfileID?.businessProductCat);

        const filteredData = res.data.filter(
          // (item) => !UserProfileID?.businessProductCat.includes(item._id)
          (item) => !ids.includes(item._id)
        );

        setServiceOptions(filteredData.map((item) => ({
          value: item._id,
          label: item.name,
        })) || []);
      }
    } catch (error) {
      console.error("Error occured!", error);
    }
  };

  useEffect(() => {
    console.log("useefect63", UserProfileID?.businessProductCat);
    getCompanyTypeWiseServices(UserProfileID?.companyTypeID||data?.companyTypeID)
  }, [])



  const handleServiceChange = (selected) => {
    setSelectedOptions(selected);
  };

  return (
    <div className="edittamodalboixshadow">
      <div className="edittamodalboixsbg">
        <div className="crossdivicon" onClick={() => closemodal(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="edittappduudivnhhb">
          <h4 className="upldbannertrtxtpp">Add Services</h4>
        </div>
        <div className="abattstdovvstttschgg">
          {/* <textarea
            value={addService}
            onChange={handleChange}
            rows={4}
            cols={4}
            className="addservicetextdivatreediv"
            placeholder="Enter service details..."
          /> */}

          <Select
            options={serviceOptions}
            isMulti
            value={selectedOptions}
            onChange={handleServiceChange}
            placeholder="Select services..."
          />
          {/* <div>
            <strong>Selected:</strong>{" "}
            {selectedOptions.map((opt) => opt.value).join(", ")}
          </div> */}


          {/* <p className="ttsrrhhshwoolumiuitstxtt">100-250 words</p> */}
          {selectedOptions?.length > 0 && (
            <div className="svavtatvvbtnnmpaggdijbtn">
              <button
                onClick={(e) => submit(e)}
                className="svavtatvvbtnnm">
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBusinessServiceModal;
