import React from 'react'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Upgradeprofile = () => {
  const { UserProfileID } = useSelector((state) => state.app);
  const navigate = useNavigate()
  const gotoPlanListing = () => {
    console.log("JUYT9",UserProfileID);
    
    
    UserProfileID?.planUpgradeRequested ? toast.error( <>You have already made a request for upgradation !! <br />Admin will contact you shortly</>): navigate("/upgradeplan")
    // navigate("/upgradeplan")
  }
  return (
    <div className='viewsandebgagemntdiv'>
      <div className='viewsandebgagemntdpaddinf'>
        <div className=''>
          <p className='meettscysttxtpp'>Create your personal profile and meet smartly with your customers.</p>
          <p className='wehelpsppeottxpp'>
          We help people connect with opportunities.<br />Join our people accelerator business network program
          </p>
        </div>
        <div className='trtysttxnowtrtysttxndiv'>
          <button className='trtysttxnowttxtp'>Try Now</button>
          <button className='ipyysffraedtn'
            onClick={(e) => gotoPlanListing()}
            disabled={!UserProfileID?.businessStatus}
            >
              Upgrade</button>
        </div>
      </div>
    </div>
  )
}

export default Upgradeprofile
