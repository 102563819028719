import React, { useEffect, useState } from 'react'

import img from '../../Images/analyticsdashbordimage.png'
import img2 from '../../Images/Vector (26).png'
import img3 from '../../Images/Vector (27).png'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import arrowup from '../../Images/expand_less.png'
import img7 from '../../Images/Vector (29).png'
import img8 from '../../Images/calendar.png'
import companylogo from '../../Images/Logos 1.png'
import user from '../../Images/user.png'
import smalllogo from '../../Images/Vector (4).png'
import smalllogo2 from '../../Images/Vector (5).png'
import smalllogo3 from '../../Images/Vector (6).png'
import smalllogo4 from '../../Images/Group 427319809.png'
import { formatDateAgo, formatNumberToUnits } from '../../utils/Helper';
import { useDispatch, useSelector } from 'react-redux'
import { fetchAnalyticsReportOverview } from '../../Redux/Slice/AnalyticsSlice'
import { SetDisplayUserData } from '../../Redux/Slice/PostSlice'
import defaultUserImage from '../../Images/logootherblm.png'

const AnalyticsReport = () => {

    const { AnalyticsReportOverview } = useSelector((state) => state.analytics);
    const dispatch = useDispatch()


    const [dropdown, setdropdown] = useState(false)
    const [dropdown2, setdropdown2] = useState(false)
    const [showFilter, setShowFilter] = useState(false)



    const navigate = useNavigate()
    const [singleData, setSingleData] = useState()

    const handleBusinessPageView = (e, item) => {
        console.log("HYT110", item.companyName);

        e.preventDefault()
        setSingleData(item)
        dispatch(SetDisplayUserData([item]))
        navigate(`/businesspage/${item?.companyName?.toLowerCase().replace(/\s+/g, "_")}`)
        // navigate(`/MemberOtherSavedProfile/${singleData?.userName?.trim()}`)
    }

    const [activeInfoType, setActiveInfoType] = useState("account-reached")

    useEffect(() => {
        dispatch(fetchAnalyticsReportOverview())
    }, [])

    console.log("SER52", AnalyticsReportOverview);


    return (
        <>
            <section className='membermastersection' style={{
                padding: '10px 0px'
            }}>
                <div className="cust_container">
                    <div className='membermastersectionbg member_section_new_class' style={{

                        background: 'transparent',
                    }}>
                        <div className='left_analtics_div_wrapper'>
                            <div className='left_analtics_container'>
                                <div className='left_analtics_logo'>
                                    <div className='analytics_img_div'>
                                        <img src={img} />

                                    </div>
                                    <p>Analytics</p>
                                </div>
                                <div className='nav_link_wrapper'>
                                    <NavLink to={'/analytics-report'} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img2} />
                                        </div>
                                        <p>Overview</p>
                                    </NavLink>
                                    <NavLink to={'/analytics'} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>BLM page</p>
                                    </NavLink>
                                    <NavLink to={'/analytics-profile'} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>Profile</p>
                                    </NavLink>
                                </div>
                                {/* <div className='nav_link_wrapper'>
                                    <div onClick={(() => setdropdown(!dropdown))} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>BLM page</p>

                                        <img src={arrowup} style={{
                                            width: '22px',
                                            height: '22px',
                                            marginTop: '7px'
                                        }} />
                                    </div>
                                    {dropdown && <div className='blm_page_dropdown_div'>
                                        <p>Followers</p>
                                        <p>Public</p>
                                    </div>}

                                    <div onClick={(() => setdropdown2(!dropdown2))} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>Profile</p>

                                        <img src={arrowup} style={{
                                            width: '22px',
                                            height: '22px',
                                            marginTop: '7px'
                                        }} />
                                    </div>

                                    {dropdown2 && <div className='blm_page_dropdown_div'>
                                        <p>Members</p>
                                        <p>Non Members</p>
                                    </div>}
                                </div> */}
                            </div>
                            {/* <div className='export_div_wrapper'>
                                <div className='export_div'>
                                    <div className='export_img'>
                                        <img src={img5} />
                                    </div>
                                    <p>Export</p>
                                </div>
                                <div className='lock_img_div'>
                                    <img src={img6} />
                                </div>
                            </div> */}
                        </div>
                        <div className='right_analtics_div'>
                            <div className='right_analtics_div_container'>
                                <div className='overview_wrapper'>
                                    <p>Overview</p>

                                    <div className='filter_box_wrapper'>
                                        <div className='filter_box'>
                                            <img src={img7} />
                                            <p>2</p>
                                        </div>
                                        <div className='filter_box'>
                                            <img src={img8} />
                                            <span>Jan 1, 2024 - Dec 31, 2024</span>
                                        </div>
                                    </div>



                                </div>


                            </div>
                            <div className='account_wrapper'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={activeInfoType === "account-reached" ? "account_1 active_class" : "account_1"}
                                    onClick={(e) => setActiveInfoType("account-reached")}
                                >
                                    <div className='account_logo_wrapper'>
                                        <div className='account_logo_div'>
                                            <img src={smalllogo} />
                                        </div>
                                        <p>Accounts Reached</p>
                                    </div>
                                    <div className='account_reached_wrapper'>
                                        <div>
                                            <h5>{formatNumberToUnits(AnalyticsReportOverview?.TotalReachedCount || 0)}</h5>
                                            <h4>+8.4K this week</h4>
                                        </div>
                                        <h6>+1.29%</h6>
                                    </div>
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={activeInfoType === "account-engagement" ? "account_1 active_class" : "account_1"}
                                    onClick={(e) => setActiveInfoType("account-engagement")}
                                >
                                    <div className='account_logo_wrapper'>
                                        <div className='account_logo_div'>
                                            <img src={smalllogo2} />
                                        </div>
                                        <p>Accounts Engagement</p>
                                    </div>
                                    <div className='account_reached_wrapper'>
                                        <div>
                                            <h5>{formatNumberToUnits(AnalyticsReportOverview?.TotalEngagededCount || 0)}</h5>
                                            <h4>+8.4K this week</h4>
                                        </div>
                                        <h6 style={{
                                            background: " rgba(2, 177, 90, 0.15)"

                                        }}>+1.29%</h6>
                                    </div>
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={activeInfoType === "total-likes" ? "account_1 active_class" : "account_1"}
                                    onClick={(e) => setActiveInfoType("total-likes")}
                                >
                                    <div className='account_logo_wrapper'>
                                        <div className='account_logo_div'>
                                            <img src={smalllogo3} />
                                        </div>
                                        <p>Total Likes</p>
                                    </div>
                                    <div className='account_reached_wrapper'>
                                        <div>
                                            <h5>{formatNumberToUnits(AnalyticsReportOverview?.TotalLikeCount || 0)}</h5>
                                            <h4>+8.4K this week</h4>
                                        </div>
                                        <h6 style={{
                                            background: " rgba(235, 0, 27, 0.15)"

                                        }}>+1.29%</h6>
                                    </div>
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={activeInfoType === "total-members" ? "account_1 active_class" : "account_1"}
                                    onClick={(e) => setActiveInfoType("total-members")}
                                >
                                    <div className='account_logo_wrapper'>
                                        <div className='account_logo_div'>
                                            <img src={smalllogo4} />
                                        </div>
                                        <p>Total Members</p>
                                    </div>
                                    <div className='account_reached_wrapper'>
                                        <div>
                                            <h5>{formatNumberToUnits(AnalyticsReportOverview?.TotalMembersCount + AnalyticsReportOverview?.TotalNonMembersCount)}</h5>
                                            <h4>+8.4K this week</h4>
                                        </div>
                                        <h6 style={{
                                            background: " rgba(2, 177, 90, 0.15)"

                                        }}>+1.29%</h6>
                                    </div>
                                </div>
                            </div>

                            <div className='right_analtics_table_wrapper'>
                                <div className='all_actives_wrapper'>
                                    <h2>{activeInfoType === "account-reached" ? "Account Reached" : activeInfoType === "account-engagement" ? "Account Engagement" : activeInfoType === "total-likes" ? "Total Likes" : activeInfoType === "total-members" ? "Total Members" : ""}</h2>
                                    <div className='select_boxes select_boxes_2'>

                                        <input readOnly value={'Page 40'} />
                                        <input readOnly value={'Page 50'} />

                                        <select>
                                            <option value={'Last 7 Days'}>Last 7 Days</option>
                                            <option value={'today'}>Today</option>
                                            <option value={'week'}>This Week</option>
                                            <option value={'month'}>This Month</option>
                                        </select>
                                    </div>


                                </div>
                                <div className='right_table_content_wrapper'>
                                    <table>
                                        <tbody>

                                            {/* ReachedData
                                        LikedData */}
                                            {
                                                activeInfoType === "total-likes" && AnalyticsReportOverview.LikedData.map((e, i) => {
                                                    return (
                                                        <>
                                                            <tr style={{
                                                                borderBottom: ' 1px solid rgba(195, 195, 195, 1)'
                                                            }}>
                                                                <td className='table_data_1'>
                                                                    <div className='company_logo_details_Wrappper'
                                                                        style={{
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={(event) => handleBusinessPageView(event, e?.likedByData)}
                                                                    >
                                                                        <div className='logo_img656'>
                                                                            <img src={e?.likedByData?.businessProfilePic} title={e?.likedByData?.companyName} />
                                                                        </div>
                                                                        <div className='logo_details656'>
                                                                            <p>{e?.likedByData?.companyName}</p>
                                                                            <span>{e?.likedByData?.companyTypeName}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className='table_data_2'>
                                                                    Liked your profile
                                                                </td>
                                                                <td className='table_data_3'>
                                                                    {formatDateAgo(e?.createdOn)}
                                                                </td>
                                                                <td className='table_data_4'>
                                                                    <div>
                                                                        <img src={user} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                (activeInfoType === "account-reached" || activeInfoType === "account-engagement") && AnalyticsReportOverview.ReachedData.map((e, i) => {
                                                    return (
                                                        <>
                                                            <tr style={{
                                                                borderBottom: ' 1px solid rgba(195, 195, 195, 1)'
                                                            }}>
                                                                <td className='table_data_1'>
                                                                    <div className='company_logo_details_Wrappper'
                                                                        style={{
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={(event) => handleBusinessPageView(event, e?.visitedByData)}
                                                                    >
                                                                        <div className='logo_img656'>
                                                                            <img src={e.visitedByData.businessProfilePic || defaultUserImage} title={e?.visitedByData?.companyName} alt={e?.visitedByData?.businessProfilePic} />
                                                                        </div>
                                                                        <div className='logo_details656'>
                                                                            <p>{e?.visitedByData?.companyName}</p>
                                                                            <span>{e?.visitedByData?.companyTypeName}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className='table_data_2'>
                                                                    Visited your profile
                                                                </td>
                                                                <td className='table_data_3'>
                                                                    {formatDateAgo(e?.createdOn)}
                                                                </td>
                                                                <td className='table_data_4'>
                                                                    <div>
                                                                        <img src={user} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }



                                            {/* <tr style={{
                                                borderBottom: ' 1px solid rgba(195, 195, 195, 1)'
                                            }}>
                                                <td className='table_data_1'>
                                                    <div className='company_logo_details_Wrappper'
                                                        style={{
                                                            cursor: "pointer"
                                                        }}

                                                    >
                                                        <div className='logo_img656'>
                                                            <img src={companylogo} />
                                                        </div>
                                                        <div className='logo_details656'>
                                                            <p>{'Neoo Search FZE'}</p>
                                                            <span>{'Advertising & Marketing'}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='table_data_2'>
                                                    Your page view
                                                </td>
                                                <td className='table_data_3'>
                                                    {'15 min ago'}
                                                </td>
                                                <td className='table_data_4'>
                                                    <div>
                                                        <img src={user} />
                                                    </div>
                                                </td>
                                            </tr> */}



                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AnalyticsReport
