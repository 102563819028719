import React, { useEffect, useState } from "react";
import mainplanlofgo from "../../Images/mainplanlofgo.png";
import HttpClient from "../../utils/HttpClient";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Modal/CustomToast.css";

const Paymenyshoppingcomp = ({ data, setData }) => {
  const { SearchBar, ProfileSubmenuDropDownToggle, UserProfileID } =
    useSelector((state) => state.app);
  const [selectedPlan, setSelectedPlans] = useState();
  const Navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [promoCodes, setPromoCodes] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState();
  const [loadingPromos, setLoadingPromos] = useState(false);
  const [price, setPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(price);
  const [modalOpen, setmodalOpen] = useState(false)
  // console.log("dattaaaa : ", data);
  // subscriptionId
  console.log("LINE18", UserProfileID, data);

  console.log("Dattaaaa : ", selectedPlan);
  useEffect(() => {
    getPlan();
  }, []);

  useEffect(() => {
    if (plans.length > 0) {
      findPlan();
    }
  }, [plans, data?.subscriptionId]);

  const findPlan = () => {
    console.log("item?._id === data?.subscriptionId : all");
    const plan = plans?.find((item) => {
      console.log(
        "item?._id === data?.subscriptionId :",
        item?._id === data?.subscriptionId
      );
      return item?._id === data?.subscriptionId;
    });
    console.log("item?._id === data?.subscriptionId :", plan);
    if (plan) {
      setSelectedPromo();
      // setPrice(plan?.amount); // Set base price here--
      setPrice(plan?.actualPrice);
      setSelectedPlans(plan);
      // setFinalPrice(plan?.amount); // Set initial final price to be the same as price
      setFinalPrice(plan?.actualPrice);
    }
  };

  const getPlan = async () => {
    const res = await HttpClient.requestData("subscription-plans", "GET", "");
    if (res && res.status) {
      setPlans(res?.data.reverse());
    } else {
      console.log("Error from getting subscription:", res);
    }
  };
  const handlePlanChange = (plan) => {
    setData((prevData) => ({ ...prevData, subscriptionId: plan?._id }));
    setSelectedPlans(plan);
    setSelectedPromo();
    setPrice(plan?.amount); // Update price when plan changes
    setFinalPrice(plan?.amount); // Reset final price to the base price
  };

  const timeCalCulate = (years) => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + years);
    return currentDate.toDateString();
  };

  const fetchPromoCodes = async () => {
    setLoadingPromos(true);
    let endpoint = "";
    if (selectedPlan === undefined) {
      endpoint = data?.subscriptionId;
    } else {
      endpoint = selectedPlan?._id;
    }
    const res = await HttpClient.requestData(
      `promocodes/${endpoint}`,
      "GET",
      ""
    );

    if (res && res.status) {
      setPromoCodes(res.data);
    } else {
      console.error("Error fetching promo codes:", res);
    }
    setLoadingPromos(false);
  };

  const handlePromoCodeClick = () => {
    setShowPromoModal(true);
    fetchPromoCodes();
    setPromoCodes();
  };

  const applyPromoCode = (code) => {
    // setData((prevData) => ({ ...prevData, promoCode: code }));
    setSelectedPromo(code);
    setShowPromoModal(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const [isPromoApplied, setIsPromoApplied] = useState(false)
  const applyCode = () => {
    if (selectedPromo) {
      const percentage = selectedPromo?.percentage;
      const discount = (percentage / 100) * price;
      const calculatedFinalPrice = price - discount;
      setFinalPrice(calculatedFinalPrice);
      setIsPromoApplied(true)
    }
  };

  const checkOut = async () => {
    setData((prev) => ({
      ...prev,
      promocodeId: selectedPromo?._id,
      paymentActualAmount: selectedPlan?.amount,
      paymentAfterPromoAmount: finalPrice,
      businessStatus: false,
    }));

    const updatedBusinessData = {
      ...data,
      // Add new properties
      promocodeId: selectedPromo?._id,
      paymentActualAmount: selectedPlan?.amount,
      paymentAfterPromoAmount: finalPrice,
      businessStatus: false,
      country: "63dcc310811c87e00cd3a614",
    };

    // Remove properties
    delete updatedBusinessData._id;
    // delete updatedBusinessData.password;
    delete updatedBusinessData.token;
    delete updatedBusinessData.SLNo;
    delete updatedBusinessData.TypeOfCompany;
    delete updatedBusinessData.avgRating;
    delete updatedBusinessData.designation;
    delete updatedBusinessData.doc;
    delete updatedBusinessData.likeCount;
    delete updatedBusinessData.likes;
    delete updatedBusinessData.loginStatus;
    delete updatedBusinessData.memberStatus;
    delete updatedBusinessData.partnerStatus;
    // delete updatedBusinessData.passwordView;
    delete updatedBusinessData.productData;
    delete updatedBusinessData.rating;
    delete updatedBusinessData.referralStatus;
    delete updatedBusinessData.service;
    delete updatedBusinessData.serviceID;
    delete updatedBusinessData.serviceName;
    delete updatedBusinessData.upgradeStatus;
    delete updatedBusinessData.companyTypeName;

    // console.log("LINE129", updatedBusinessData,UserProfileID._id);


    createBusinessListing(updatedBusinessData)
    //Thank you message start
    // toast.success(
    //   <div className="custom-toast">
    //      <div className="toast-icon">
    //       <span>&#10004;</span> 
    //     </div> 
    //     <div className="toast-message">
    //       <strong>Thank you!</strong>
    //       <p>
    //         Successfully Created your page. Password sent via email upon registration.
    //       </p>
    //       <small>Please check your page listing status in your Admin.</small>
    //     </div>
    //   </div>,
    //   {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //   }
    // );
    setmodalOpen(!modalOpen)

    //Thank you message end

    // return

    // try {
    //   const response = await HttpClient.requestData(
    //     "business-list",
    //     "POST",
    //     data
    //   );
    //   if (response.status) {
    //     Navigate("/searchhome");
    //     Navigate("/");
    //   } else {
    //     console.error("Error from listing business");
    //   }
    // } catch (error) {
    //   console.error("Error submitting data:", error);
    // } finally {
    // }
  };

  const createBusinessListing = async (requestData) => {
    try {
      const response = await HttpClient.requestData(
        "business-list",
        "POST",
        requestData
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");

        //Thank you message
        toast.custom((t) => (
          <div
            className={`custom-toast-container ${t.visible ? "show" : "hide"}`}
            role="alert"
          >
            <div className="toast-icon">
              <span>&#10004;</span> {/* Checkmark icon */}
            </div>
            <div className="toast-message">
              <strong>Thank you!</strong>
              <p>
                Successfully Created your page. Password sent via email upon
                registration.
              </p>
              <small>Please check your page listing status in your Admin.</small>
            </div>
          </div>
        ));



      } else {
        console.error("Error from listing business");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
    }
  }

  const navigate = useNavigate()
  const CustomModal = () => {
    return (
      <>
        <div className="custom_modal_wrap"></div>
        <div className="custom_modal_container">
          <div className="custom_green">
            <i class="fa-solid fa-check"></i>
          </div>
          <h3>Thank You</h3>
          <h5>Successfully Created Your Page</h5>
          <h6>Password sent via email upon registration</h6>
          <p>Please check your page listing status in your Admin</p>
          <div className="d-flex justify-content-center mt-3">
            <button className="done_btn_button" onClick={((e) => {
              setmodalOpen(!modalOpen)
              navigate("/user-login")
            })}>Done</button>
          </div>
        </div>
      </>
    )
  }

  // console.log("PLD285", plans);

  return (
    <>
      <div className="paymentshpinhgcomp">
        <div className="arrowmshpingtasuvdivflx">
          <p className="shpingtxttxpp">Shopping Continue</p>
          <div className="arrowmaindesuv">
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>

        <div className="shoopingcarddivmain">
          <div className="shoopingcarddivflx">
            <div className="shoopingcarddivleft">
              <div className="">
                <p className="shpingtxttxpp">Shopping cart</p>
                <p className="shpingtxttxppdesctxtp">
                  You have {plans.length} items in your cart; select any one plan
                </p>
                <div className="allpancarddiovmain">
                  {plans.map((item, index) => (
                    <div className="allpancarddiovbrdr" key={item._id}>
                      <div className="allpancarddiovbrdrdsplflx">
                        <div className="allpancarddiovbrdrmain">
                          <label className="custom-checkbox">
                            <input
                              type="radio"
                              name="subscriptionPlan"
                              checked={item._id === data?.subscriptionId}
                              onChange={() => handlePlanChange(item)}
                            />
                            {/* <span className="custom-checkbox-box"></span> */}
                          </label>
                        </div>
                        <div className="alllogopayemdtdibmabk">
                          <div
                            className="alllogopayemdtdibmabkdiv"
                            style={{
                              backgroundColor:
                                item.plan === "Basic"
                                  ? "#5C2881"
                                  : item.plan === "Silver"
                                    ? "#8C959D"
                                    : item.plan === "Gold"
                                      ? "#C08329"
                                      : "defaultColor",
                            }}
                          >
                            <div className="alllogopayemdtdibimg">
                              <img src={mainplanlofgo} alt="..." />
                            </div>
                          </div>
                        </div>
                        <div className="alllogopayemdtdvdata">
                          <p className="pandivisttstxppp">{item.plan} Plan</p>
                          <div className="pandivisttstxpppdediv">
                            <p className="pandivisttstxpppdescttxp">
                              {item.validity}
                            </p>
                            <p className="pandivisttstxpppdescttxp">
                              {item.user}
                            </p>
                          </div>
                        </div>
                        <div className="alllogopayemdtdvdatanewleft">
                          <p className="dataplannewlefttxp">{item.type}</p>
                          <p className="dataplannewlefttxpaladA">
                            PAY NOW AED {item.amount}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="subtotaltxtpppdivmain">
                    <p className="subtotaltxtppp">
                      Subtotal: <span> AED {selectedPlan?.amount}</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="shoopingcarddivright">
              <div className="shoopingcarddivbrdrmain">
                <div className="shoopingcarddmaimndivflx">
                  <p className="basicplavtxtppppmnatpp">
                    {selectedPlan?.plan} Plan
                  </p>
                  <div className="deleteicondiv">
                    <i class="fa-regular fa-trash-can"></i>
                  </div>
                </div>
                <div className="drpdownyeartptaleddiv">
                  <div className="drpdownyeartptaleddivflx">
                    <div className="drpdownyeartptaledleft">
                      <div className="drowdowncustdivbrdr">
                        <div className="drowdowncusttetiixdivdivbrdr">
                          <p className="drowdownsettxtppss">2 Years</p>
                          <div className="drowdownsettxtppssiciih">
                            <i className="fa-solid fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="drpdownyeartptaledright">
                      <p className="aesdtxttpppttxpp">
                        AED {selectedPlan?.actualPrice}
                      </p>
                    </div>
                  </div>
                  <div className="drpdownyeartptaleddiv">
                    <p className="Busilistingnesstxtp">
                      Business listing Renews on:
                      <br /> {timeCalCulate(2)}{" "}
                    </p>
                  </div>
                </div>
                <div className="divpromiocodesavedivmain">
                  <p className="enterpromocodegxtpp">
                    Enter your promo code and save 50%
                  </p>
                  <div className="prpmocodedivbrdr">
                    <input
                      value={selectedPromo?.promoCode || ""}
                      type="text"
                      contentEditable={false}
                      className="prpmocodedivbrdrinput"
                      placeholder="promo code"
                    />
                    {selectedPromo && (
                      <button
                        onClick={applyCode}
                        className="promocodeebterdivbtn"
                      >
                        Enter
                      </button>
                    )}
                  </div>
                  <p onClick={handlePromoCodeClick} className="applyttxpp">
                    Apply promo code
                  </p>
                </div>

                {showPromoModal && (
                  <div className="promoModal">
                    <div className="modalContent">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Select a Promo Code</h5>
                        <i
                          onClick={() => setShowPromoModal(false)}
                          className="fa fa-times"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        ></i>
                      </div>
                      <hr />
                      {loadingPromos ? (
                        <p>Loading promo codes...</p>
                      ) : (
                        <ul>
                          {promoCodes.map((promo) => (
                            <li style={{ alignItems: "center" }} key={promo.id}>
                              <div>
                                <b>
                                  {promo.promoCode} - {promo.percentage}% off
                                </b>
                                <p>{formatDate(promo?.validTo)}</p>
                              </div>
                              <button
                                style={{
                                  backgroundColor: "#007BFF",
                                  color: "#FFFFFF",
                                  borderRadius: "5px",
                                  padding: "3px 6px",
                                  border: "none",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  transition: "background-color 0.3s",
                                  fontSize: "14px",
                                }}
                                onClick={() => applyPromoCode(promo)}
                                onMouseOver={(e) =>
                                  (e.target.style.backgroundColor = "#0056b3")
                                }
                                onMouseOut={(e) =>
                                  (e.target.style.backgroundColor = "#007BFF")
                                }
                              >
                                Apply
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                )}

                <div className="alltotalpayemtxtswneoodiv">
                  <div className="alltotalpayemtxtstablemain">
                    <table className="alltotalpayemtxtstable">
                      <tr className="alltotalpayemtxtstabletr">
                        <td className="alltotalpayemtxtstabletd">
                          <p className="alltabledatatppptxtpp">Subtotal</p>
                        </td>
                        <td className="alltotalpayemtxtstabletd">
                          <p className="alltabledatatrightppptxtpp">
                            AED {finalPrice}
                          </p>
                        </td>
                      </tr>
                      <tr className="alltotalpayemtxtstabletr">
                        <td className="alltotalpayemtxtstabletd">
                          <p className="alltabledatatppptxtpp">Shipping</p>
                        </td>
                        <td className="alltotalpayemtxtstabletd">
                          <p className="alltabledatatrightppptxtpp">AED 0</p>
                        </td>
                      </tr>
                      <tr className="alltotalpayemtxtstabletr">
                        <td className="alltotalpayemtxtstabletd">
                          <p className="alltabledatatppptxtpp">
                            Total (Tax incl.)
                          </p>
                        </td>
                        <td className="alltotalpayemtxtstabletd">
                          <p className="alltabledatatrightppptxtpp">AED 0</p>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <button
                    disabled={!isPromoApplied}
                    onClick={checkOut}
                    className="aedtbtbtxttpppdivbytbntbtn"
                  >
                    <p className="aedtbtbtxttppp">AED {finalPrice}</p>
                    <p className="aedtbtbtxttpppchckttp">
                      Checkout
                      <span className="arrowdibbnammcttap">
                        <i className="fa-solid fa-arrow-right-long"></i>
                      </span>
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <p className="trmsacondtiontxtp">Terms and conditions</p>

            <div className="trmsconditiontxtlistdiv">
              <ul className="trmsconditiontxtul">
                <li className="trmsconditiontxtli">
                  Only registered companies can be listed in the Neoo Search local
                  directory.
                </li>
                <li className="trmsconditiontxtli">
                  Company listing page will be made live after the admin has
                  verified it.
                </li>
                <li className="trmsconditiontxtli">
                  Company listing page will go live after two working days after
                  receiving the payment.
                </li>
                <li className="trmsconditiontxtli">
                  The product will be delivered after 4 working days.
                </li>
                <li className="trmsconditiontxtli">
                  The client must provide the required content for the page and
                  profile.
                </li>
                <li className="trmsconditiontxtli">
                  The company profile will be created by admin only.
                </li>

                <li className="trmsconditiontxtli">
                  Payment will not be refunded after the page is live.
                </li>
                <li className="trmsconditiontxtli">
                  Business pack is provided for lifetime
                </li>
                <li className="trmsconditiontxtli">
                  Business listing must be renewed annually.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {modalOpen && <CustomModal />}
    </>

  );
};

export default Paymenyshoppingcomp;
