import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const Viewersengagement = () => {
    const { UserProfileID } = useSelector((state) => state.app);
    console.log("ENG7", UserProfileID);

    return (
        <div className='viewsandebgagemntdiv'>
            <div className='viewsandebgagemntdivmain'>
                <table className='tavbedebieisdtldiv'>
                    <tr className='tavbedebieisdtldivtr'>
                        <td className='tavbedebieisdtldivtrd'>Profile Views  </td>
                        <td className='tavbedebieisdtldivtrd'>
                        {UserProfileID?.profileVisitCount || 0}
                        </td>
                    </tr>
                    <tr className='tavbedebieisdtldivtr'>
                        <td className='tavbedebieisdtldivtrd'>Follower Engagement  </td>
                        <td className='tavbedebieisdtldivtrd'>
                            {UserProfileID?.businessFollowCount || 0}
                        </td>
                    </tr>
                </table>
                <div className='vuisrrcannsiittxtpmaindib'>
                    {
                        UserProfileID?.businessStatus ? (
                            <Link className="vuisrrcannsiittxtp" to="/analytics-report">View analytics</Link>
                        ) : (
                            <p className="vuisrrcannsiittxtp" style={{ color: "#b0beb9", cursor: "not-allowed" }}>View analytics</p>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default Viewersengagement
