import React, { useContext, useEffect, useRef, useState } from "react";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import vectorarrow from "../../Images/vectorarrow.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Selectplancomp from "./Selectplancomp";
import Workprogressbar from "./Workprogressbar";
import HttpClient from "../../utils/HttpClient";

const Listbusinessform = () => {
  const colordata = useContext(Colorcontext);
  const location = useLocation();
  const navigate = useNavigate();
  const { choosePlanID, username, password } = location.state || {};
  const [toggleDesignation, setToggleDesignation] = useState(false);
  const [toggleTypeOfBussiness, setToggleTypeOfBussiness] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [toggleCountry, setToggleCountry] = useState(false);
  const [designationName, setDesignationName] = useState("");
  const [bussinessName, setBussinessName] = useState("");

  const [drowdowsncustom, setDrowdowsncustom] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({ _id: "", name: "" });
  const [plans, setPlans] = useState([]);
  const [country, setCountry] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [company, setCompany] = useState([]);
  const [countryState, setCountryState] = useState([]);
  const [formData, setFormData] = useState({
    subscriptionId: selectedPlan?._id || choosePlanID,
    password: password,
    personName: "",
    designationID: "",
    companyTypeID: "",
    mobileNo: "",
    telephoneNo: "",
    emailId: "",
    address: "",
    emirate: "",
    companyName: "",
    webAddress: "",
    location: "",
    country: "65f95f070d6753c3bdacccd9",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    colordata.setcolor(true);
    return () => {
      colordata.setcolor(false);
    };
  }, []);

  const getState = async () => {
    const res = await HttpClient.requestData(
      "countrywise-state/" + "65f95f070d6753c3bdacccd9",
      "GET",
      {}
    );
    if (res && res?.status) {
      setCountryState(res?.data?.[0]?.states);
      console.log("res?.data?.[0]?.states : ", res?.data?.[0]?.states);
    }
  };

  useEffect(() => {
    getPlan();
    getCountry();
    getCompany();
    getDesignation();
  }, []);

  useEffect(() => {
    choosedPlan();
  }, [plans, choosePlanID]);

  useEffect(() => {
    setFormData({ ...formData, subscriptionId: selectedPlan?._id })
  }, [selectedPlan])

  const getCompany = async () => {
    const response = await HttpClient.requestData("companyType", "GET", "");
    if (response.status) {
      setCompany(response.data);
    } else {
      console.log("Error while fetching company data");
    }
  };

  const _getCountry = async () => {
    const response = await HttpClient.requestData("countries-list", "GET", "");
    if (response.status) {
      setCountry(response.data);
    } else {
      console.log("Error while fetching country data");
    }
  };

  //country
  const getCountry = async () => {
    const res = await HttpClient.requestData("countries", "GET", {});
    console.log("rescount", res);

    if (res && res?.status) {
      setCountry(res?.data);
      // setNonMemberProfileAdd({
      //   ...NonMemberProfileAdd,
      //   countryCodeMobile: res.data[0].phone_code,
      //   countryCodewhatsappNo: res.data[0].phone_code,
      // });
    }
  };




  const getDesignation = async () => {
    const response = await HttpClient.requestData("designation", "GET", "");
    if (response.status) {
      setDesignation(response.data);
    } else {
      console.log("Error while fetching designation data");
    }
  };

  const getPlan = async () => {
    const res = await HttpClient.requestData("subscription-plans", "GET", "");
    if (res && res.status) {
      setPlans(res?.data.reverse());
    } else {
      console.log("Error from getting subscription:", res);
    }
  };

  const choosedPlan = () => {
    if (plans.length === 0 || !choosePlanID) return;
    const selected = plans.find((item) => item._id === choosePlanID);
    if (selected) {
      setSelectedPlan({ _id: selected._id, name: selected.plan });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // name === "mobileNo" && (value = countryCodeMobile + value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "personName":
        if (!value) error = "Name is required";
        break;
      case "companyName":
        if (!value) error = "Company Name is required";
        break;

      // case "telephoneNo":
      //   if (!value) error = "Copany number is required";
      //   else if (!/^\d{10}$/.test(value))
      //     error = "Company number must be 10 digits";
      //   break;

      // case "mobileNo":
      //   if (!value) error = "Mobile number is required";
      //   else if (!/^\d{10}$/.test(value))
      //     error = "Mobile number must be 10 digits";
      //   break;

      case "emailId":
        if (!value) error = "Email is required";
        else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          error = "Invalid email format";
        break;
      case "address":
        if (!value) error = "Address is required";
        break;
      case "emirate":
        if (!value) error = "Emirate is required";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };

  const requiredFields = [
    { fieldID: "personName", fieldName: "Full name" },
    { fieldID: "designationID", fieldName: "Designation" },
    { fieldID: "companyTypeID", fieldName: "Type of business" },
    { fieldID: "companyName", fieldName: "Company name is required" },
    { fieldID: "mobileNo", fieldName: "Mobile number" },
    { fieldID: "emailId", fieldName: "Work email" },
    { fieldID: "address", fieldName: "Address" },
    { fieldID: "emirate", fieldName: "State" },
  ];

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Check required fields
    requiredFields.forEach((field) => {
      if (!formData[field.fieldID]) {
        newErrors[field.fieldID] = `${field.fieldName} is required`;
        isValid = false;
      }
    });

    // Validate mobile number (10 digits)
    // if (!/^\d{10}$/.test(formData.mobileNo)) {
    //   newErrors.mobileNo = "Mobile number must be 10 digits";
    //   isValid = false;
    // }


    // Validate optional telephone number (10 digits)
    // if (formData.telephoneNo && !/^\d{10}$/.test(formData.telephoneNo)) {
    //   newErrors.telephoneNo = "Telephone number must be 10 digits";
    //   isValid = false;
    // }

    // Validate email format
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailId)) {
      newErrors.emailId = "Invalid email format";
      isValid = false;
    }

    console.log("FormData221", formData, isValid);
    console.log("isValid : ", newErrors);
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    

    const updatedFormData = {
      ...formData,
      mobileNo: (formData.countryCodeMobile || '+971') + ' ' + formData.mobileNo,
      telephoneNo: (formData.countryCodePhone || '+971') + ' ' + formData.telephoneNo,
    };
    // Remove countryCodeMobile
    // delete updatedFormData.countryCodeMobile;
    // delete updatedFormData.countryCodePhone;

    if (selectedPlan._id === "") {
      const findIndex = plans.findIndex((item, index) => {
        return item.plan === "Basic";
      });
      selectedPlan._id = plans[findIndex]._id;
    }
    // console.log("formData227", updatedFormData);
    if (validateForm()) {
      formData.subscriptionId = selectedPlan._id;
      // console.log("Form submitted successfully");
      console.log("GGD258", updatedFormData);

      navigate("/ownblmprofile", {
        state: { formData: updatedFormData },
      });
    } else {
      console.log("Error");
    }
  };


  const divDesignationRef = useRef(null);
  const divTypeOfBusinessRef = useRef(null);
  const divStateRef = useRef(null);
  const divCountryRef = useRef(null);

  const handleClickOutside = (event) => {
    // alert(1)
    if (divDesignationRef.current && !divDesignationRef.current.contains(event.target)) {
      console.log("Clicked outside the div!");
      setToggleDesignation(false)
    }
    if (divTypeOfBusinessRef.current && !divTypeOfBusinessRef.current.contains(event.target)) {
      console.log("Clicked outside the div!");
      setToggleTypeOfBussiness(false)
    }
    if (divStateRef.current && !divStateRef.current.contains(event.target)) {
      console.log("Clicked outside the div!");
      setToggleState(false)
    }
    if (divCountryRef.current && !divCountryRef.current.contains(event.target)) {
      console.log("Clicked outside the div!");
      setToggleCountry(false)
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <section className="listbusinessformdivmain">
        <div className="cust_container">
          <div className="listbusinessdivflx">
            <div className="listbusinessdivleft">
              <div className="Workprogressbarmainb">
                <Workprogressbar page={2} />
              </div>

              <div className="">
                <Selectplancomp
                  plans={plans}
                  setSelectedPlan={setSelectedPlan}
                  selectedPlan={selectedPlan}
                />
              </div>

              <div className="listbusinessdivleftbg">
                <p className="conattaindptxtpp">Contact information</p>

                <div className="contatcindofdicfplx">
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      Full name <span>*</span>
                    </p>
                    <input
                      type="text"
                      name="personName"
                      value={formData.personName}
                      onChange={handleInputChange}
                      placeholder="John Doe"
                      className="inpustxocntbusuitxtpp"
                    />
                    {errors.personName && (
                      <span className="error">{errors.personName}</span>
                    )}
                  </div>

                  <div ref={divDesignationRef} className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      {" "}
                      Designation <span> *</span>
                    </p>
                    <div className="dropwdowndivfrmbusinee" onClick={() => setToggleDesignation(!toggleDesignation)}>
                      {formData?.designationID ? (
                        <p
                          style={{
                            color: "#000",
                            fontWeight: "500",
                          }}
                          className="vectoraarowdivomgulmaili"
                        >
                          {designationName}
                        </p>
                      ) : (
                        <p className="vectoraarowdivomgulmaili">
                          ---Choose---
                        </p>
                      )}
                      <div
                        className="vectoraarowdivomg"
                        onClick={() => setToggleDesignation(!toggleDesignation)}
                      >
                        <img
                          src={vectorarrow}
                          style={{
                            cursor: "pointer",
                          }}
                          alt="..."
                        />
                      </div>
                      {toggleDesignation && (
                        <div className="vectoraarowdivomgulmai">
                          <ul className="vectoraarowdivomgulmaiul">
                            {designation.map((item, index) => {
                              return (
                                <li
                                  className="vectoraarowdivomgulmaili"
                                  key={index}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      designationID: item?._id,
                                    });
                                    setDesignationName(item?.designation);
                                    setToggleDesignation(!toggleDesignation);
                                  }}
                                >
                                  {item?.designation}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                    {errors.designationID && (
                      <span className="error">{errors.designationID}</span>
                    )}
                  </div>
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      Company name <span> *</span>
                    </p>
                    <input
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      placeholder="Company name"
                      className="inpustxocntbusuitxtpp"
                    />
                    {errors.companyName && (
                      <span className="error">{errors.companyName}</span>
                    )}
                  </div>
                  <div ref={divTypeOfBusinessRef} className="contatcindofdiinpur"
                    onClick={() =>
                      setToggleTypeOfBussiness(!toggleTypeOfBussiness)
                    }
                  >
                    <p className="contatcindofdiinpurtxtp">
                      {" "}
                      Type of business <span> *</span>
                    </p>
                    <div className="dropwdowndivfrmbusinee">
                      {formData?.companyTypeID ? (
                        <p
                          className="vectoraarowdivomgulmaili"
                          style={{
                            color: "#000",
                            fontWeight: "500",
                          }}
                        >
                          {bussinessName}
                        </p>
                      ) : (
                        <p className="vectoraarowdivomgulmaili">
                          Select type of business
                        </p>
                      )}
                      <div
                        className="vectoraarowdivomg"
                        onClick={() =>
                          setToggleTypeOfBussiness(!toggleTypeOfBussiness)
                        }
                      >
                        <img
                          src={vectorarrow}
                          style={{
                            cursor: "pointer",
                          }}
                          alt="..."
                        />
                      </div>
                      {toggleTypeOfBussiness && (
                        <div className="vectoraarowdivomgulmai">
                          <ul className="vectoraarowdivomgulmaiul">
                            {company.map((item, index) => {
                              return (
                                <li
                                  className="vectoraarowdivomgulmaili"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      companyTypeID: item._id,
                                      companyTypeName: item?.type
                                    });
                                    setToggleTypeOfBussiness(
                                      !toggleTypeOfBussiness
                                    );
                                    setBussinessName(item?.type);
                                  }}
                                >
                                  {item.type}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                    {errors.companyTypeID && (
                      <span className="error">{errors.companyTypeID}</span>
                    )}
                  </div>
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">Company number</p>
                    <div className="input-wrapper">
                      <select
                        className="_form-control _countrycodebox inpustxocntbusuitxtpp"
                        aria-label="Default select example"
                        name="countryCodePhone"
                        style={{
                          width: "30%",
                          background: "#fff",
                        }}
                        value={formData.countryCodePhone}
                        onChange={handleInputChange}
                      >
                        {country.map((item, i) => (
                          <option key={i} value={item?.phone_code} selected={item?.phone_code === "+971"}>
                            {item?.emoji} {item?.phone_code}
                          </option>
                        ))}
                      </select>
                      <input
                        // type="number"
                        name="telephoneNo"
                        value={formData.telephoneNo}
                        onChange={handleInputChange}
                        placeholder="Company number"
                        className="inpustxocntbusuitxtpp"
                        style={{
                          width: "70%"
                        }}
                      />
                    </div>
                    {errors.telephoneNo && (
                      <span className="error">{errors.telephoneNo}</span>
                    )}
                  </div>
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      Mobile number<span> *</span>
                    </p>
                    <div className="input-wrapper">
                      <select
                        className="_form-control _countrycodebox inpustxocntbusuitxtpp"
                        aria-label="Default select example"
                        name="countryCodeMobile"
                        style={{
                          width: "30%",
                          background: "#fff",
                        }}
                        value={formData.countryCodeMobile || "+971"}
                        onChange={handleInputChange}
                      >
                        {country.map((item, i) => (
                          <option key={i} value={item?.phone_code} selected={item?.phone_code === "+971"}>
                            {item?.emoji} {item?.phone_code}
                          </option>
                        ))}
                      </select>
                      <input
                        name="mobileNo"
                        value={formData.mobileNo}
                        onChange={handleInputChange}
                        placeholder="Mobile number"
                        className="inpustxocntbusuitxtpp"
                        style={{
                          width: "70%"
                        }}
                      />
                    </div>
                    {errors.mobileNo && (
                      <span className="error">{errors.mobileNo}</span>
                    )}
                  </div>
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      Work email <span> *</span>
                    </p>
                    <input
                      type="text"
                      name="emailId"
                      value={formData.emailId}
                      onChange={handleInputChange}
                      placeholder="info@companyname.com"
                      className="inpustxocntbusuitxtpp"
                    />
                    {errors.emailId && (
                      <span className="error">{errors.emailId}</span>
                    )}
                  </div>
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      Web address <span> </span>
                    </p>
                    <input
                      type="text"
                      name="webAddress"
                      value={formData.webAddress}
                      onChange={handleInputChange}
                      placeholder="www.webname.com"
                      className="inpustxocntbusuitxtpp"
                    />
                  </div>
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      Location <span> </span>
                    </p>
                    <input
                      type="text"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      placeholder="Palm Jumeirah"
                      className="inpustxocntbusuitxtpp"
                    />
                  </div>
                  <div className="contatcindofdiinpur">
                    <p className="contatcindofdiinpurtxtp">
                      Address <span> *</span>
                    </p>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      placeholder="Jumeirah tower #123, D block"
                      className="inpustxocntbusuitxtpp"
                    />
                    {errors.address && (
                      <span className="error">{errors.address}</span>
                    )}
                  </div>
                  <div ref={divStateRef} className="contatcindofdiinpur" onClick={() => setToggleState(!toggleState)}>
                    <p className="contatcindofdiinpurtxtp">
                      {" "}
                      State <span> *</span>
                    </p>
                    <div className="dropwdowndivfrmbusinee">
                      {formData?.emirate ? (
                        <p
                          className="vectoraarowdivomgulmaili"
                          style={{
                            color: "#000",
                            fontWeight: "500",
                          }}
                        >
                          {formData?.emirate}
                        </p>
                      ) : (
                        <p className="vectoraarowdivomgulmaili">State*</p>
                      )}
                      <div
                        className="vectoraarowdivomg"
                        onClick={(e) => {
                          getState()
                          setToggleState(!toggleState)
                        }}
                      >
                        <img onClick={getState} src={vectorarrow} alt="..." />
                      </div>
                      {toggleState && (
                        <div className="vectoraarowdivomgulmai">
                          <ul className="vectoraarowdivomgulmaiul">
                            {countryState.map((item, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      emirate: item.name,
                                    });
                                    setToggleState(!toggleState);
                                  }}
                                  className="vectoraarowdivomgulmaili"
                                >
                                  {item.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                    {errors.emirate && (
                      <span className="error">{errors.emirate}</span>
                    )}
                  </div>

                  <div ref={divCountryRef} className="contatcindofdiinpur" onClick={() => setToggleCountry(!toggleCountry)}>
                    <p className="contatcindofdiinpurtxtp">
                      Country <span> *</span>
                    </p>
                    <div className="dropwdowndivfrmbusinee">
                      <p
                        className="vectoraarowdivomgulmaili"
                        style={{
                          color: "#000",
                          fontWeight: "500",
                        }}
                      >
                        United Arab Emirate
                      </p>
                      <div
                        className="vectoraarowdivomg"
                        onClick={() => setToggleCountry(!toggleCountry)}
                      >
                        <img src={vectorarrow} alt="..." />
                      </div>
                      {toggleCountry && (
                        <div className="vectoraarowdivomgulmai">
                          <ul className="vectoraarowdivomgulmaiul">
                            <li className="vectoraarowdivomgulmaili">
                              United Arab Emirate
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="contatcindofdiinpurnewalasda">
                    <p className="contatcindofdiinpurtxtp">
                      Location map <span> </span>
                    </p>
                    <input
                      type="text"
                      placeholder="United arab emirate"
                      className="inpustxocntbusuitxtpp"
                    />
                  </div> */}
                </div>

                <div className="btnnexttdivbtndivmain">
                  {/* <Link className='btnnexttdivbtn' to="/businesinfoform">Next</Link> */}
                  <Link
                    onClick={handleSubmit}
                    className="btnnexttdivbtn"
                  // to="/ownblmprofile"
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
            <div className="listbusinessdivrightt"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Listbusinessform;
