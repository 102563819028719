import { React, useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import FixedFooter from "./FixedFooter";
import { useEffect } from "react";
import {
  ProfileSubmenuDropDown,
  SearchModal,
  pwaDataSet,
} from "../Redux/Slice/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import instapp from "../Images/logo-w.png";
import PWA from "../Component/PWA";
import ColorContext, { Colorcontext } from "../Component/Colorcontext/ColorContext";

const MainLayout = () => {
  const { SearchBar, ProfileSubmenuDropDownToggle, pwaData, UserProfileID } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(SearchModal(false));
  }, []);
  useEffect(() => {
    dispatch(SearchModal(false));
  }, []);
  // useEffect(() => {
  //   if (UserProfileID) {
  //     if (UserProfileID?.memberStatus) {
  //       navigate("/profile/" + UserProfileID?.userName?.trim());
  //     } else if (!UserProfileID?.memberStatus) {
  //       navigate("/NonMemberuserprofile/" + UserProfileID?.personName?.trim());
  //     }
  //   }
  // }, [UserProfileID]);
  // const colorData = useContext(Colorcontext);
  return (
    <ColorContext>
      <>
        <div
          onClick={() => {
            dispatch(ProfileSubmenuDropDown(false));
            dispatch(SearchModal(false));
          }}
          className="mainwrap"
        >
          <PWA />
          <Header />
          <div
          // className=""
          // onClick={() => {
          //   dispatch(ProfileSubmenuDropDown(false));
          //   dispatch(SearchModal(false));
          // }}
          >
            <Outlet />
          </div>
          <Footer />
          <FixedFooter />
        </div>
      </>
    </ColorContext>

  );
};

export default MainLayout;
