import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";

const PlanList = () => {
  const { UserProfileID } = useSelector((state) => state.app);
  console.log("LINE07X", UserProfileID);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPlan = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("subscription-plans", "GET", "");
    if (res && res.status) {
      setData(res?.data.reverse());
    } else {
      console.log("Error from getting subscription : ", res);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPlan();
  }, []);

  const handleUpgradeRequest = async (e) => {
    e.preventDefault()
    const res = await HttpClient.requestData("upgradation-plan-request", "PUT", { upgradedSubscriptionId: e.target.id });

    //return
    if (res && res.status) {
      const response = await HttpClient.requestData("request-upgrade", "GET", {});
      console.log("IUYYY37", response);
      confirmAlert({
        // title: "Confirm to delete",
        // message: `Are you sure you want to delete "${item}"?`,
        title: (
          <h4>Upgrade to {e.target.name} plan</h4>
        ),
        message: (
          <p>
            Admin will contact you Shortly..
          </p>
        ),
        buttons: [
          {
            label: "Ok",
            // onClick: () => removeService(indexToRemove),
            style: { background: "#ff0000" },
          },
          // {
          //   label: "No",
          //   onClick: () => alert("Action canceled!"),
          // },
        ],
      });
      // setData(res?.data.reverse());

    } else {
      console.log("Error from getting subscription : ", res);
    }

  }

  return (
    <>
      <section className="choseplan">
        <div className="choosplantxdivmain">
          <h4 className="choosplantxttpp">Change Your Plan</h4>
          <p className="fromstartuptxttp">From Startup to Enterprise</p>
          <p className="pisckktxtpp">
            Pick an account plan that fits your marketing
          </p>
        </div>

        <div className="chooseplandivmaon">
          <div className="chooseplandivmaondivflx">
            {loading ? (
              <div className="loader">Loading plans...</div>
            ) : (
              data
                // .filter(item => item._id !== UserProfileID.subscriptionId)
                .map((item, index) => {
                  return (
                    <div
                      className="chooseplandivmaondivbrdr "
                      key={index}
                      style={
                        item.plan === "Gold" ? { backgroundColor: "#662D91" } : {}
                      }
                    >
                      <div className="chooseplandivmaondivbrdrmain">
                        <button
                          className="basiuicvaytbtu"
                          style={
                            item.plan === "Gold"
                              ? { color: "#FFF", borderColor: "#fff", fontWeight: "600" }
                              : { fontWeight: "600" }
                          }
                        >
                          {item?.plan} plan
                        </button>
                        {
                          item?.offPercentage &&
                          <>
                            <p
                              className="freesttxppp"
                              style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                            >
                              {item?.offPercentage}
                            </p>
                          </>
                        }
                        {
                          item?.actualPrice && item?.actualPrice !== item?.amount ?
                            <>
                              <p
                                className="freesttxppp"
                                style={item.plan === "Gold" ? { color: "#FFF", font: "500 24px / 30px Roboto" } : { font: "500 24px / 30px Roboto" }}
                              >
                                <span className="currencySym">AED</span>{item?.amount}<span className="currencySym" style={{ margin: "0 5px 0 0" }}>{item?.validity ? `/${item?.validity}` : ''}</span>
                                <span className="currencySym">AED</span>
                                <span
                                  className="colored-strike"
                                  style={item.plan === "Gold" ? { marginRight: "0px", color: '#FFF' } : { marginRight: "0px" }}
                                >{item?.actualPrice}</span>

                              </p>
                            </> :
                            <>
                              <p
                                className="freesttxppp"
                                style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                              >
                                <span className="currencySym">AED</span>{item?.amount}
                              </p>
                            </>
                        }

                        {/* <div className="">
                        <p
                          className="freesttxppp"
                          style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                        >
                          {item?.type}
                        </p>
                        <p
                          className="auuhshtxttpp"
                          style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                        >
                          {item?.user}
                        </p>
                      </div> */}

                        <div className="baysyysttxtpppdsidnbmai">
                          <p
                            className="baysyysttxtppp"
                            style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                          >
                            {item.plan === "Basic" ? "Basic listing" : item.plan === "Silver" ? "Standard listing" : item.plan === "Gold" ? "Premium listing" : ""}

                          </p>
                          <ul className="busuyyasbasytxtxtppul ">
                            {item?.listing?.map((it, index) => {
                              return (
                                <li
                                  key={index}
                                  className="busuyyasbasytxtxtppli"
                                  style={
                                    item.plan === "Gold" ? { color: "#FFF" } : {}
                                  }
                                >
                                  {it}
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        {
                          item?.businessPack?.length > 0 &&
                          <>
                            <div className="baysyysttxtpppdsidnbmai">
                              <p
                                className="baysyysttxtppp"
                                style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                              >
                                Business pack

                              </p>

                              {/* <p
                              className="auuhshtxttpp"
                              style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                            >
                              {item?.user}
                            </p> */}
                              <ul className="busuyyasbasytxtxtppul plan_border"
                                style={
                                  item.plan === "Gold" ? { border: '2px solid white' } : { border: '2px solid rgb(101, 45, 144)' }
                                }
                              >
                                <p
                                  className="auuhshtxttpp"
                                  style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                                >
                                  {item?.user}
                                </p>

                                {item?.businessPack?.map((it, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="busuyyasbasytxtxtppli"
                                      style={
                                        item.plan === "Gold" ? { color: "#FFF" } : {}
                                      }
                                    >
                                      {it}
                                    </li>
                                  );
                                })}
                              </ul>


                            </div>
                          </>
                        }
                        {/* {
                          (UserProfileID.subscriptionId === "66d89409094058c609c71832" && item._id !== "66d89409094058c609c71832")
                          || (UserProfileID.subscriptionId === "66d897a2094058c609c71836" && item._id !== "66d89409094058c609c71832" && item._id !== "66d897a2094058c609c71836")
                          || (UserProfileID.subscriptionId === "66d897d8094058c609c7183c" && item._id !== "66d89409094058c609c71832" && item._id !== "66d897a2094058c609c71836" && item._id !== "66d897d8094058c609c7183c")  &&
                          <Link className="choosttappanbtn"
                            id={item._id}
                            name={item.plan}
                            onClick={(e) => handleUpgradeRequest(e)}
                          >
                            Upgrade
                          </Link>
                        } */}

                        {
                          (UserProfileID.subscriptionId === "66d89409094058c609c71832" && item._id !== "66d89409094058c609c71832") &&
                          <Link className="choosttappanbtn"
                            id={item._id}
                            name={item.plan}
                            onClick={(e) => handleUpgradeRequest(e)}
                          >
                            Upgrade
                          </Link>
                        }
                        {
                          (UserProfileID.subscriptionId === "66d897a2094058c609c71836" && item._id !== "66d89409094058c609c71832" && item._id !== "66d897a2094058c609c71836") &&
                          <Link className="choosttappanbtn"
                            id={item._id}
                            name={item.plan}
                            onClick={(e) => handleUpgradeRequest(e)}
                          >
                            Upgrade
                          </Link>
                        }
                        {
                          (UserProfileID.subscriptionId === "66d897d8094058c609c7183c" && item._id !== "66d89409094058c609c71832" && item._id !== "66d897a2094058c609c71836" && item._id !== "66d897d8094058c609c7183c") &&
                          <Link className="choosttappanbtn"
                            id={item._id}
                            name={item.plan}
                            onClick={(e) => handleUpgradeRequest(e)}
                          >
                            Upgrade
                          </Link>
                        }





                        {/* <div className="Viewbtnmain">
                          <Link to="/" className="vieiimitrrebtn">
                            View more
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PlanList;
