import React, { useEffect, useRef, useState } from 'react';
import crosssimgiocn from "../../Images/crosssimgiocn.png"
import Neoologoasjji from "../../Images/Neoologoasjji.png";
// import postbanneimmhg from "../../Images/postbanneimmhg.png";
import postbanneimmhg from "../../Images/cameralogo.png";
import prodlikeicon from "../../Images/prodlikeicon.png";
import prodlikedicon from "../../Images/liked.png";
import commenytstproodiuificon from "../../Images/commenytstproodiuificon.png";
import msgiocn from "../../Images/msgiocn.png";
import Photography from "../../Images/Photography.png";
import logiiaihsui from "../../Images/logiiaihsui.png";
import cntnctimg3 from "../../Images/cntnctimg3.png";
import cmtprofiletwoimg from "../../Images/cmtprofiletwoimg.png"
import Ownpostpagemodal from '../../Modal/Ownpostpagemodal';
import HttpClient from '../../utils/HttpClient';
import { formatDateAgo } from '../../utils/Helper';
import { useDispatch, useSelector } from 'react-redux';
import ItemLoader from '../../Component/ItemLoader';
import PostComments from './PostComments';
import PostEnqueyModal from '../../Modal/PostEnqueyModal';
import { getSingleUserProfile, SetDisplayUserData, ShareModalonOff } from '../../Redux/Slice/PostSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ProfileShare from '../../Modal/ProfileShare';
import { deletePageEnquiry, fetchPageEnquiries } from '../../Redux/Slice/EnquirySlice';
import moment from 'moment';

const BlmPageEnquiries = ({ filter, setCounter }) => {
    const { status, PageEnquiries } = useSelector((state) => state.enquiry);
    const { ShareModalOpenClose, UserProfileID, SingleUserData } = useSelector((state) => state.app);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    console.log("GGS35", filter);


    const [loadmtersdiumodal, setLoadmtersdiumodal] = useState(false);
    const [handleownpostmodal, sethandleownpostmodal] = useState(false)
    const loadmoresfgbtbnbclokj = () => {
        setLoadmtersdiumodal(!loadmtersdiumodal)
    }
    const [threedpotsspclmodal, setThreedpotsspclmodal] = useState(false);
    const [ownpostcommetclickmodal, setownpostcommetclickmodal] = useState(false)

    const [menuPostID, setMenuPostID] = useState()
    const threedotssettingclcil = (e, id) => {
        e.preventDefault()
        setMenuPostID(id)
        threedpotsspclmodal ? setThreedpotsspclmodal(true) : setThreedpotsspclmodal(!threedpotsspclmodal)
        // setThreedpotsspclmodal(true)

    }

    const [commentPostID, setCommentPostID] = useState()
    const ownposcommentclcik = (e, id) => {
        e.preventDefault()
        setCommentPostID(id)
        ownpostcommetclickmodal ? setownpostcommetclickmodal(true) : setownpostcommetclickmodal(!ownpostcommetclickmodal)
        // setownpostcommetclickmodal(true)
        // setThreedpotsspclmodal(!threedpotsspclmodal)
    }

    const [queryPostID, setQueryPostID] = useState()
    const [sendQueryModal, setSendQueryModal] = useState(false)
    const handleQuery = (e, id, userId) => {
        e.preventDefault()
        setQueryPostID(id)
        UserProfileID?._id !== userId && setSendQueryModal(!sendQueryModal)
    }

    const [selectedPostData, setSelectedPostData] = useState()
    const handleopwmnpostmodal = (e, item) => {
        e.preventDefault()
        setSelectedPostData(item)
        sethandleownpostmodal(!handleownpostmodal)
    }

    //Display Posts
    const [allData, setAllData] = useState([])

    const [visibleItems, setVisibleItems] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(3);
    const loadMoreItems = 1;

    const handleLoadMore = () => {
        // Load more items by increasing the itemsToShow count
        setItemsToShow((prev) => prev + loadMoreItems);
    };

    useEffect(() => {
        // getMyBusinessPosts()
        // params.id && !UserProfileID ? navigate('/user-login') :
        UserProfileID ? dispatch(fetchPageEnquiries()) : navigate('/user-login')

    }, [])

    useEffect(() => {
        // setVisibleItems(PageEnquiries.slice(0, itemsToShow));

        const today = new Date();

        if (filter === "today") {
            // Format today's date to YYYY-MM-DD
            const todayString = today.toISOString().split("T")[0];
            setVisibleItems(
                [...PageEnquiries]
                    .filter((post) => {
                        // Extract only the date part from createdOn
                        const createdDate = post.createdOn.split("T")[0];
                        return createdDate === todayString;
                    })
                // .slice(0, itemsToShow)
            )
        } else if (filter === "week") {
            // Get the first day of the current week (Sunday)
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay());
            startOfWeek.setHours(0, 0, 0, 0); // Set to start of the day

            // Get the last day of the current week (Saturday)
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            endOfWeek.setHours(23, 59, 59, 999); // Set to end of the day

            setVisibleItems(
                [...PageEnquiries]
                    .filter((post) => {
                        const createdDate = new Date(post.createdOn);
                        return createdDate >= startOfWeek && createdDate <= endOfWeek;
                    })
                // .slice(0, itemsToShow)
            )
        } else if (filter === "month") {
            const currentMonth = today.getMonth(); // 0-based index (0 = January)
            const currentYear = today.getFullYear();

            setVisibleItems([...PageEnquiries]
                .filter((post) => {
                    const createdDate = new Date(post.createdOn);
                    return (
                        createdDate.getMonth() === currentMonth && createdDate.getFullYear() === currentYear
                    );
                })
                // .slice(0, itemsToShow)
            )
        } else {
            setVisibleItems(
                [...PageEnquiries]
                // .slice(0, itemsToShow)
            )
        }
    }, [itemsToShow, PageEnquiries, filter]);

    useEffect(() => {
        setCounter(visibleItems.length)
    }, [visibleItems])

    console.log("PARAMS160", params?.id, visibleItems);

    // const [likeCounter, setLikeCounter] = useState(0)
    // const [isLikeByMe, setIsLikeByMe] = useState(false)
    // const [activePostId, setActivePostId] = useState("")



    const handlePostLike = async (e, data) => {
        e.preventDefault()
        if (UserProfileID?._id === data?.profileId) return
        try {
            const response = await HttpClient.requestData(
                "business-post-like",
                "POST",
                { postId: data?._id }
            );
            if (response.status) {
                // Navigate to payment page
                // Navigate("/searchhome");
                // Navigate("/");
                // dispatch(getOwnProfile())

                // setActivePostId(data?._id)

                // setIsLikeByMe(!isLiked)

                // setLikeCounter(isLiked ? likeCounter - 1 : likeCounter + 1)

                // if(data?.PostLikeData.some(item => item.likedBy === UserProfileID?._id)){
                //     setIsLikeByMe(true)
                //     setLikeCounter(likeCounter - 1)
                // }else{
                //     setIsLikeByMe(false)
                //     setLikeCounter(likeCounter + 1)
                // }



                console.log("RESP", response, data);

            } else {
                console.error("Error in Like/Dislike");
                console.log("RESP", response, data);
            }
        } catch (error) {
            console.error("Error submitting Like/Dislike:", error);
            console.log("RESP", error, data);
        } finally {
            // dispatch(LoadBusinessPostsInBackground())
        }
    }

    const gotoBusinessListing = (e, user) => {
        e.preventDefault()
        dispatch(getSingleUserProfile(user?._id))
        navigate(`/businesspage/${user?.companyName?.toLowerCase().replace(/\s+/g, "_")}`)

        // alert(userId)
    }

    const hidePost = (e) => {
        e.preventDefault()
        setThreedpotsspclmodal(false)
    }

    const location = useLocation();
    const copyPostLink = (e, postId) => {
        e.preventDefault()
        console.log("location166", location);
        const postUrl = `${window.location.origin}/ownpostpage/${postId}`;
        navigator.clipboard
            .writeText(postUrl)
            .then(() => {
                // alert("Copied")
                toast.success("Copied Successfully");
                setThreedpotsspclmodal(false)
                // setCopied(true);
                // setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            })
            .catch((err) => console.error('Failed to copy text: ', err));
    }
    const [sharemodal, setSharemodal] = useState(false);
    const [postLink, setPostLink] = useState("")
    const shareClick = (e, postId) => {
        e.preventDefault()
        const postUrl = `${window.location.origin}/ownpostpage/${postId}`;
        setPostLink(postUrl)
        setSharemodal(!sharemodal);
        dispatch(ShareModalonOff(true));
    };

    const divRef = useRef(null);
    const handleClickOutside = (e) => {
        // Check if the click is outside the referenced div
        if (divRef.current && !divRef.current.contains(e.target)) {
            console.log('Clicked outside of the div');
            // Call your method here
            // alert(1)
            setThreedpotsspclmodal(false)
        }
    };
    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setIsModalOpen(false);
    };

    const _deleteEnquiry = async (e, id) => {
        e.preventDefault()
        setActDelete(true)
        try {
            const response = await HttpClient.requestData(
                "_business-post-like",
                "POST",
                { postId: id }
            )
            if (response.status) {
                toast.success("Enquiry removed")
            } else {
                console.error("Error in Delete Enquiry");
            }
        } catch (error) {
            console.error("Error in Delete Enquiry:", error);
            toast.error("Enquiry removed")
        } finally {

            dispatch(fetchPageEnquiries())
        }
    }

    const [actDelete, setActDelete] = useState(false)

    const deleteEnquiry = async (e, id) => {
        e.preventDefault()
        setActDelete(true)
        dispatch(deletePageEnquiry(id))
        dispatch(fetchPageEnquiries())
    }

    const handleSingleData = (e,item) => {
        // setSingleData(item)
        // console.log("Sin230", item);
    
        dispatch(SetDisplayUserData([item]))
        navigate(`/businesspage/${item?.companyName?.toLowerCase().replace(/\s+/g, "_")}`)
        
      }


    return (
        <>
            {
                status === "loading" && !actDelete ? (
                    <>
                        <ItemLoader />
                    </>
                ) : (
                    <>
                        <div className='allpostowndmaimndivmain'>
                            {
                                visibleItems.map((enquiryItem, index) => {
                                    return (
                                        <>
                                            {/* Single Post Start */}
                                            <div className='allpostowndmaimndiv'
                                            style={{cursor:"pointer"}}
                                            onClick={(e)=>handleSingleData(e,enquiryItem?.enqueryByData)}
                                            >

                                                <div className='allpostimagedesuigfnatinob'
                                                    style={{
                                                        marginBottom: "5px",
                                                        paddingBottom: "5px",
                                                        // borderBottom: "solid 1px #928f8f"
                                                    }}
                                                >
                                                    <div className='allposttslodjjimg'>
                                                        <img src={enquiryItem?.enqueryByData?.businessProfilePic || Neoologoasjji} alt='...'
                                                        // onClick={() => openModal(enquiryItem?.PostData?.file || Neoologoasjji)}
                                                        />
                                                    </div>
                                                    <div className=''>
                                                        <h4 className='neeporoalposttsheadr'>{enquiryItem?.enqueryByData?.companyName}</h4>
                                                        {/* <p className='cmskshhstdiifgysyttxtpop'><span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p> */}
                                                        <p className='cmskshhstdiifgysyttxtpop'>Posted on: {moment(enquiryItem?.PostData?.createdOn).format('DD-MMM-YYYY')}</p>
                                                    </div>
                                                </div>



                                                <div className='allpostowndmaimndivallelemnt'>

                                                    <div className='allpostowndmaimndivallelemnt'>
                                                        <p><strong>Name:</strong> {enquiryItem?.name}<br /><strong>Phone:</strong> {enquiryItem?.phoneNo}<br /><strong>Email:</strong> {enquiryItem?.workEmail}</p>
                                                    </div>


                                                    <div className='crosasddoproidikdsjbhidivflx'>
                                                        {/* <div className='threediostyiicpnggsuc'>
                                                            <div className='threediostyiicpn' onClick={(e) => threedotssettingclcil(e, enquiryItem?._id)}>
                                                                <i className="fa-solid fa-ellipsis"></i>
                                                            </div>
                                                            {(threedpotsspclmodal && menuPostID === enquiryItem?._id) &&
                                                                <div className='threedotssettingdiv'
                                                                    ref={divRef}
                                                                >
                                                                    <ul className="userMenu">
                                                                        <li>
                                                                            <a href="javascript:"
                                                                                onClick={(e) => deleteEnquiry(e, enquiryItem?._id)}
                                                                            >
                                                                                <i className="fa-solid fa-trash-can mr-2"></i>
                                                                                Delete Enquiry
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>}
                                                        </div> */}
                                                        <div className='crossidovpostimgdiv'
                                                            onClick={(e) => deleteEnquiry(e, enquiryItem?._id)}
                                                        >
                                                            <img src={crosssimgiocn} alt='...' />
                                                        </div>

                                                    </div>



                                                </div>

                                                <div className='postbannettimhdivflxmakflx'>
                                                    {/* <div className='postbanneimmhgdivjakhjimh'
                                                        onClick={(e) => handleopwmnpostmodal(e, enquiryItem)}
                                                    >
                                                        <img src={enquiryItem?.file || postbanneimmhg} alt='' style={{ borderRadius: "12px", cursor: "pointer" }} />
                                                    </div> */}
                                                    <div className='poosttalouindtdettstctpopdoiv'
                                                    // onClick={(e) => handleopwmnpostmodal(e, enquiryItem)}
                                                    // style={{
                                                    //     cursor: "pointer"
                                                    // }}
                                                    >
                                                        <p><strong>Type: </strong>{enquiryItem?.typeOfEnquiry || ""}</p>
                                                        <p><strong>Subject: </strong>{enquiryItem?.enquirySubject || ""}</p>
                                                        {/* <h4>{enquiryItem?.enquirySubject || "..."}</h4> */}
                                                        <p className='postimagedesrcptoioponttxp' style={{marginTop:"10px"}}>
                                                            {enquiryItem?.enquiry || "..."}
                                                        </p>
                                                        {
                                                            enquiryItem?.file &&
                                                            <div className='_postbanneimmhgdivjakhjimh'
                                                                onClick={() => openModal(enquiryItem?.file || postbanneimmhg)}
                                                            >
                                                                <img src={enquiryItem?.file || postbanneimmhg} alt=''
                                                                    style={{ borderRadius: "8px", cursor: "pointer", maxWidth: "64px" }}
                                                                />
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='htrrstrtdttgsgbtxttppgh'>{formatDateAgo(enquiryItem?.createdOn)}</p>
                                                </div>
                                                <div className="allpostlikecommetsendenqeryudiv">
                                                    {/* <div className="prodctlikecmntenquerypagediv">
                                                        <div className="produucvtsdlikeinmncomnfdivvhjjd"
                                                            onClick={(e) => { handlePostLike(e, post) }}
                                                        >
                                                            <div className="produucvtsdlikeinmncomnf">
                                                                <img
                                                                    src={post?.PostLikeData.some(item => item.likedBy === UserProfileID?._id) ? prodlikedicon : prodlikeicon}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className="likkskjsbtcomnbvtyoipuxtt">
                                                                Like <span>{post?.PostLikeCount}</span>
                                                            </p>
                                                        </div>

                                                        <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={(e) => ownposcommentclcik(e, post?._id)}>
                                                            <div className="produucvtsdlikeinmncomnf">
                                                                <img
                                                                    src={commenytstproodiuificon}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className="likkskjsbtcomnbvtyoipuxtt">Comment</p>
                                                        </div>

                                                        {sendQueryModal &&
                                                            <><PostEnqueyModal closemodal={setSendQueryModal} post={queryPostID} /></>
                                                        }


                                                        <div className="produucvtsdlikeinmncomnfdivvhjjd"
                                                            onClick={(e) => handleQuery(e, post?._id, post?.userData?._id)}
                                                        >
                                                            <div className="produucvtsdlikeinmncomnf">
                                                                <img
                                                                    src={msgiocn}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                                                        </div>
                                                    </div> */}
                                                    {
                                                        // ownpostcommetclickmodal && commentPostID === post?._id &&
                                                        // <div className='ownpostcommentdiv'>
                                                        //     {
                                                        //         UserProfileID._id !== post?.profileId &&
                                                        //         <div className='ownpostcommentdivmain'>
                                                        //             <div className="cntnctimgdivnamin">
                                                        //                 <img src={UserProfileID?.profilePhoto || cntnctimg3} alt={UserProfileID?.personName || "..."} />
                                                        //             </div>

                                                        //             <div className='textareadibmainmainbrdr'>
                                                        //                 <div className='textareadibmain'>
                                                        //                     <textarea rows={4} cols={4} className='textareadibmainrerare'
                                                        //                         placeholder='Write a Comment'
                                                        //                         onChange={(e) => setCommentText(e.target.value)}
                                                        //                         value={commentText || ""}
                                                        //                     />
                                                        //                 </div>
                                                        //                 <div className="mesagsendidvmain"
                                                        //                     onClick={(e) => handleCommentSend(e, post?._id)}>
                                                        //                     <i class="fa-solid fa-paper-plane" style={{ cursor: "pointer" }}></i>
                                                        //                 </div>
                                                        //             </div>

                                                        //         </div>
                                                        //     }

                                                        //     {
                                                        //         post?.PostCommentData.length>0 &&
                                                        //         post?.PostCommentData.map((comment, index) => {
                                                        //             return (
                                                        //                 <>
                                                        //                     <div className='ownpostothercommentdivmain'>
                                                        //                         <div className='profikellcmnjhhrrddivflxttst'>
                                                        //                             <div className='profikellcmntimgdivflxttst'>
                                                        //                                 <div className='commentproofilleimg'>
                                                        //                                     <img src={comment?.commentedBy[0]?.profilePhoto || cmtprofiletwoimg} alt='...' />
                                                        //                                 </div>
                                                        //                                 <p className='commporosttxttnamepp'>{comment?.commentedBy[0]?.personName}</p>
                                                        //                             </div>
                                                        //                             <p className='commporosttxttnamepp'>{formatDateAgo(comment?.createdOn)}</p>
                                                        //                         </div>
                                                        //                         <p className='commsoprpodttctxppp'>
                                                        //                             {comment?.comment}
                                                        //                         </p>
                                                        //                     </div>
                                                        //                 </>
                                                        //             )
                                                        //         })
                                                        //     }
                                                        //     {/* <div className='ownpostothercommentdivmain'>
                                                        //         <div className='profikellcmnjhhrrddivflxttst'>
                                                        //             <div className='profikellcmntimgdivflxttst'>
                                                        //                 <div className='commentproofilleimg'>
                                                        //                     <img src={cmtprofiletwoimg} alt='...' />
                                                        //                 </div>
                                                        //                 <p className='commporosttxttnamepp'>John Doe</p>
                                                        //             </div>
                                                        //             <p className='commporosttxttnamepp'>1 hour ago</p>
                                                        //         </div>
                                                        //         <p className='commsoprpodttctxppp'>
                                                        //             I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                                        //         </p>
                                                        //     </div> */}

                                                        // </div>
                                                        // <PostComments data={post?.PostCommentData} postProfileId={post?.profileId} postId={post?._id} />
                                                    }
                                                </div>
                                            </div>
                                            {/* //Single Post End */}

                                        </>
                                    )
                                })
                            }

                            {/* {
                                PageEnquiries?.length > visibleItems?.length &&
                                <>
                                    <div className='lodmrebtnmainndivmaindib'>
                                    <button className='lodmrebtnmainndiv'
                                        onClick={handleLoadMore}
                                    >
                                        Load More
                                    </button>
                                </div>
                                </>
                            } */}

                            {/* {ShareModalOpenClose && (
                                <ProfileShare
                                    whatsApp={postLink}
                                    linkedInLink={postLink}
                                    facebookLink={postLink}
                                    userLink={postLink}
                                    instaLink={postLink}
                                    emailId={postLink}
                                    closeModal={setSharemodal}
                                />
                            )} */}




                        </div>
                        {/* {handleownpostmodal && <Ownpostpagemodal closemodal={sethandleownpostmodal} data={selectedPostData} />} */}

                        {/* Modal for Larger Image */}
                        {isModalOpen && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100vw",
                                    height: "100vh",
                                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: 1000,
                                }}
                                onClick={closeModal}
                            >
                                <img
                                    src={selectedImage}
                                    alt="Large View"
                                    style={{
                                        maxWidth: "90%",
                                        maxHeight: "90%",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                                    }}
                                />
                            </div>
                        )}
                    </>
                )
            }

        </>
    )
}

export default BlmPageEnquiries
