import React, { useEffect, useState } from "react";
import editicon from "../../Images/editicon.png";
import cameralogo from "../../Images/cameralogo.png";
import likec from "../../Images/like/like-c.png";
import { Rating } from "react-simple-star-rating";
import logoownblm from "../../Images/logoownblm.png";
import Uploadlogomodal from "../../Modal/Uploadlogomodal";
import Editlogogetmodal from "../../Modal/Editlogogetmodal";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import HttpClient from "../../utils/HttpClient";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import { Link } from "react-router-dom";
import Gold from "../../Images/Gold badge.svg";
import Silver from "../../Images/Silver badge.svg";
import toast, { useToaster } from "react-hot-toast";

const EditProfileblm = ({ updateForm, data }) => {

  console.log("YYTR20",data);
  

  const dispatch = useDispatch()

  const { UserProfileID } = useSelector((state) => state.app);
  const [rating, setRating] = useState(0);
  const [editlogomodal, seteditlogomodal] = useState(false);
  const [editlogomaodalaa, setEditlogomaodalaa] = useState(false);
  const [url, setUrl] = useState(data?.businessProfilePic || "");

  // console.log("FFADATA", UserProfileID);

  const handleChoosenRating = (rate) => {
    setRating(Math.abs(rate))
  }
  // Catch Rating value
  const handleRating = async () => {
    if (UserProfileID?._id === data?._id) return
    try {
      const response = await HttpClient.requestData(
        "business-profile-rating",
        "POST",
        { profileId: data?._id, rating: Math.abs(rating) }
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");
        // dispatch(getOwnProfile())
        // toast.success("Ratted successfully")
        toast.success(response?.msg)
        setRating(0)
        setModal(!modal)


        console.log("RESP", response, data);

      } else {
        setRating(0)
        setModal(!modal)
        toast.error(response?.msg)
        // console.error("Error in Rating");
        // console.log("RESP", response, data);
      }
    } catch (error) {
      console.error("Error submitting Rating:", error);
      console.log("RESP", error, data);
    } finally {

    }
  };

  const editlogomodalclick = () => {
    seteditlogomodal(!editlogomodal);
  };

  const editlogoclickspcl = () => {
    setEditlogomaodalaa(!editlogomaodalaa);
  };

  const [likeCounter, setLikeCounter] = useState()
  const [isLikeByMe, setIsLikeByMe] = useState(false)

  const handleLike = async () => {
    if (UserProfileID?._id === data?._id) return
    try {
      const response = await HttpClient.requestData(
        "business-profile-like",
        "POST",
        { profileId: data?._id }
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");
        // dispatch(getOwnProfile())

        setLikeCounter(isLikeByMe ? likeCounter - 1 : likeCounter + 1)
        setIsLikeByMe(!isLikeByMe)
        console.log("RESP", response, data);

      } else {
        console.error("Error in Like/Dislike");
        console.log("RESP", response, data);
      }
    } catch (error) {
      console.error("Error submitting Like/Dislike:", error);
      console.log("RESP", error, data);
    } finally {
    }
  }

  //Follow
  const [followCounter, setFollowCounter] = useState(0)
  const [isFollowedByMe, setIsFollowedByMe] = useState(false)

  const handleFollow = async () => {
    if (UserProfileID?._id === data?._id) return
    try {
      const response = await HttpClient.requestData(
        "business-profile-follow",
        "POST",
        { profileId: data?._id }
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");
        // dispatch(getOwnProfile())

        setFollowCounter(isFollowedByMe ? followCounter - 1 : followCounter + 1)
        setIsFollowedByMe(!isFollowedByMe)
        console.log("RESP", response, data);

      } else {
        console.error("Error in Follow/Unfollow");
        console.log("RESP", response, data);
      }
    } catch (error) {
      console.error("Error submitting Follow/Unfollow:", error);
      console.log("RESP", error, data);
    } finally {
    }
  }

  useEffect(() => {
    setLikeCounter(data?.businessLikeCount || 0)
    setIsLikeByMe(data?.businessLikes?.some(item => item.likedBy === UserProfileID?._id))
    setFollowCounter(data?.businessFollowCount || 0)
    setIsFollowedByMe(data?.businessFollow?.some(item => item.followedBy === UserProfileID?._id))
    // console.log("DATA47", data?.businessLikes, UserProfileID?._id, isLiked);
    // setRating(data?.PageAvgRating || 0)
    console.log("DATA111P", data?.businessProfilePic);
  }, [data])
  const [modal, setModal] = useState(false)
  const Modal = () => {
    return (
      <>
        <div className="modal_wrapper_main8789" onClick={(() => setModal(!modal))}></div>
        <div className="rating_modal_div">
          <p>It's wonderful to hear that you are satisfied with out products or services. Would you like to rate us?</p>
          <div className="rating_di52666v mt-2">
            <Rating
              onClick={handleChoosenRating}
              initialValue={rating}
              size={30}
            />
          </div>
          <div className="submit_btn_wrapper d-flex justify-content-center mt-4">
            <button style={{
              backgroundColor: 'rgb(103, 48, 142)',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              padding: '5px 20px',
              cursor: 'pointer',
              transition: '0.3s'
            }} className="submit_btn"
              onClick={() => handleRating()}
            >Submit</button>
          </div>
          <i class="fa-solid fa-xmark cross_btn89" onClick={(() => setModal(!modal))}></i>
        </div>
      </>
    )
  }
  return (

    <>
      {modal && <Modal />}
      <div className="editprofileblm ">
        {
          UserProfileID?._id === data?._id && updateForm !== undefined &&
          <div className="editeiconimg" onClick={editlogoclickspcl}>
            <img src={editicon} alt="..." />
          </div>
        }



        <div className="editprofile">
          {data?.businessProfilePic ? (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={data?.businessProfilePic}
              alt="..."
            />
          ) : (
            <div className="cameralogodivimgabslt">
              <div className="cameralogodivimg">
                <img src={cameralogo} alt="..." />
              </div>
              <p className="uplsdttxttppshji">Upload Logo</p>
              <p className="pixelsttxttppio">
                (Pixel Size : 140 x 140 PNG/JPEG )
              </p>
            </div>
          )}

          {/* <div className='profileimhaggemaindib'>
                        <img src={logoownblm} alt='...' />
                    </div> */}
        </div>
        <div className="">
          {/* <p className="ocensttxttpppp">{data?.personName}</p>*/}
          {/* <p className="ocensttxttpppp">{data?.companyName}</p> */}
          <p className="ocensttxttpppp">
            {

            }
            <Link
              to={UserProfileID?._id === data?._id && `/businesspage/${UserProfileID?.companyName?.toLowerCase().replace(/\s+/g, "_")}`}
              className="ocensttxttpppp _profileLink"
            >{data?.companyName}</Link>

          </p>
          <p className="adverttstyusuppp">{data?.TypeOfCompany || data?.companyTypeName}</p>
        </div>

        <div className="likesfolwoerdivfxls">
          <span className="likstrtxttpp">{likeCounter} {likeCounter > 1 ? "Likes" : "Like"}</span>

          <span className="likstrtxttpp">{followCounter} {followCounter > 1 ? "Followers" : "Follower"}</span>
        </div>

        <div className="likedfollowdivflx">
          <button className="likedfollowlike"
            onClick={handleLike}
          >
            <img src={likec} alt="..." className="img-fluid"
              style={{
                transform: isLikeByMe ? 'scaleY(-1)' : 'none',
                transition: 'transform 0.3s ease',
              }}
            />
            {isLikeByMe ? "Dislike" : "Like"}
          </button>
          <button className="foolowndnbyhbnbtn"
            onClick={handleFollow}
          >
            {isFollowedByMe ? "Unfollow" : "Follow"}
          </button>
        </div>
        {
          (UserProfileID?._id === data?._id && (UserProfileID?.SubscriptionData?.plan === "Gold" || UserProfileID?.SubscriptionData?.plan === "Silver")) &&
          <div
            style={{ textAlign: "center" }}>
            <img src={UserProfileID?.SubscriptionData?.plan === "Gold" ? Gold : UserProfileID?.SubscriptionData?.plan === "Silver" ? Silver : ""} style={{ width: "55%", maxWidth:"64px", margin:"20px 0" }} />
          </div>
        }
        {
          (UserProfileID?._id !== data?._id && (data?.subscriptionId === "66d897d8094058c609c7183c" || data?.subscriptionId === "66d897a2094058c609c71836")) &&
          <div
            style={{ textAlign: "center" }}>
            <img src={data?.subscriptionId === "66d897d8094058c609c7183c" ? Gold : data?.subscriptionId === "66d897a2094058c609c71836" ? Silver : ""} style={{ width: "55%", maxWidth:"64px", margin:"20px 0" }} />
          </div>
        }


        {/* For Mobile responsive design Start */}

        <div className="likesfolwoermobilrres">
          <button className="likedfollowlike"
            onClick={handleLike}
          >
            <img src={likec} alt="..." className="img-fluid"
              style={{
                transform: isLikeByMe ? 'scaleY(-1)' : 'none',
                transition: 'transform 0.3s ease',
              }}
            />
            {isLikeByMe ? "Dislike" : "Like"}
          </button>
          <span className="likstrtxttpp">{likeCounter} {likeCounter > 1 ? "Likes" : "Like"}</span>
        </div>

        <div className="likesfolwoermobilrres">
          <button className="foolowndnbyhbnbtn" onClick={handleFollow}>{isFollowedByMe ? "Unfollow" : "Follow"}</button>
          <span className="likstrtxttpp">{followCounter} {followCounter > 1 ? "Followers" : "Follower"}</span>
        </div>

        {/* For Mobile responsive design End */}

        <div className="starrating">
          {/* set initial value */}
          {
            UserProfileID?.businessStatus && (
              <>
                <p className="rating-value">{data?.PageAvgRating?.toFixed(1) || "0.0"}</p>
                <StarRatings
                  rating={data?.PageAvgRating}
                  starRatedColor="#F3660B"
                  starEmptyColor="grey"
                  numberOfStars={5}
                  // name="UserProfileRating"
                  starDimension="30px"
                  starSpacing="5px"
                  className="fa-solid fa-star"
                />
                {/* <Rating
                  initialValue={data?.PageAvgRating}
                  size={30}
                  readonly
                /> */}
              </>
            )
          }

        </div>

        {
          UserProfileID?._id !== data?._id &&
          <>
            <div style={{
              cursor: 'pointer',
            }} onClick={(() => setModal(!modal))} className="rating_button">
              <i class="fa-regular fa-star" style={{
                color: '#fac108'
              }}></i>
              Tap to Rate
            </div>
          </>
        }




      </div>

      {editlogomodal && <Uploadlogomodal closemodal={seteditlogomodal} />}

      {editlogomaodalaa && (
        <Editlogogetmodal
          updateForm={updateForm}
          setUrl={setUrl}
          url={url || data?.businessProfilePic}
          closemodal={setEditlogomaodalaa}
        />
      )}
    </>
  );
};

export default EditProfileblm;
