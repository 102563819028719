import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../utils/HttpClient';

//get Page enquiries'
export const fetchAnalyticsBasicDetails = createAsyncThunk('Data/AnalyticsBasicDetails', async () => {
    const response = await HttpClient.requestData("analytics", "GET", {})
    console.log("HDAHD7", response)
    return response;
});

//get Post enquiries'
export const fetchAnalyticsFollowers = createAsyncThunk('Data/AnalyticsFollowers', async () => {
    const response = await HttpClient.requestData("all-followers", "GET", {});
    console.log("HDHD14", response)
    return response.data;
});

//get Report'
export const fetchAnalyticsReportOverview = createAsyncThunk('Data/AnalyticsReportOverview', async () => {
    const response = await HttpClient.requestData("overview-analytics", "GET", {});
    console.log("HDHD21", response)
    return response;
});

//get Profile Report'
export const fetchAnalyticsProfileReport = createAsyncThunk('Data/AnalyticsProfileReport', async () => {
    const response = await HttpClient.requestData("profile-analytics", "GET", {});
    console.log("HDHD28", response)
    return response;
});


// Create the Data slice
const analyticsSlice = createSlice({
    name: 'AnalyticsData',
    initialState: {
        AnalyticsBasicDetails: {},
        AnalyticsFollowers: [],
        AnalyticsReportOverview: {
            TotalLikeCount: 0,
            TotalReachedCount: 0,
            TotalEngagededCount: 0,
            TotalMembersCount: 0,
            TotalNonMembersCount: 0,
            LikedData: [],
            ReachedData: []
        },
        AnalyticsProfileReport:{
            TotalMembersCount: 0,
            TotalNonMembersCount: 0,
            TotalLikeCount: 0,
            totalProfileAvgRating: 0,
            hitsData: [],
            allFollowersData: [],
        },
        AnalyticsStatus: '',
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            // Fetching My Business Page Enquiry Data
            .addCase(fetchAnalyticsBasicDetails.pending, (state) => {
                state.AnalyticsStatus = 'loading';
            })
            .addCase(fetchAnalyticsBasicDetails.fulfilled, (state, action) => {
                state.AnalyticsStatus = 'succeeded';
                console.log("AAA38", action)
                state.AnalyticsBasicDetails = action?.payload;
            })
            .addCase(fetchAnalyticsBasicDetails.rejected, (state, action) => {
                state.AnalyticsStatus = 'failed';
                state.AnalyticsBasicDetails = null;
                state.error = action?.error.message;
            })
            // Fetching My Business Posts Enquiry Data
            .addCase(fetchAnalyticsFollowers.pending, (state) => {
                state.AnalyticsStatus = 'loading';
            })
            .addCase(fetchAnalyticsFollowers.fulfilled, (state, action) => {
                state.AnalyticsStatus = 'succeeded';
                state.AnalyticsFollowers = action?.payload
                    // .filter((item)=>{return item.PostData})
                    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))

            })
            .addCase(fetchAnalyticsFollowers.rejected, (state, action) => {
                state.AnalyticsStatus = 'failed';
                state.AnalyticsFollowers = null;
                state.error = action?.error.message;
            })
            // Fetching My Analytics Overview
            .addCase(fetchAnalyticsReportOverview.pending, (state) => {
                state.AnalyticsStatus = 'loading';
            })
            .addCase(fetchAnalyticsReportOverview.fulfilled, (state, action) => {
                state.AnalyticsStatus = 'succeeded';
                state.AnalyticsReportOverview.TotalLikeCount = action?.payload?.TotalLikeCount || 0
                state.AnalyticsReportOverview.TotalReachedCount = action?.payload?.TotalReachedCount || 0
                state.AnalyticsReportOverview.TotalEngagededCount = action?.payload?.TotalEngagededCount || 0
                state.AnalyticsReportOverview.LikedData = action?.payload?.LikedData || []
                state.AnalyticsReportOverview.ReachedData = action?.payload?.ReachedData || []
            })
            .addCase(fetchAnalyticsReportOverview.rejected, (state, action) => {
                state.AnalyticsStatus = 'failed';
                state.AnalyticsReportOverview = null;
                state.error = action?.error.message;
            })

            // Fetching My Profile Analytics
            .addCase(fetchAnalyticsProfileReport.pending, (state) => {
                state.AnalyticsStatus = 'loading';
            })
            .addCase(fetchAnalyticsProfileReport.fulfilled, (state, action) => {
                state.AnalyticsStatus = 'succeeded';
                state.AnalyticsProfileReport.TotalMembersCount = action?.payload?.TotalMembersCount || 0
                state.AnalyticsProfileReport.TotalNonMembersCount = action?.payload?.TotalNonMembersCount || 0
                state.AnalyticsProfileReport.TotalLikeCount = action?.payload?.TotalLikeCount || 0
                state.AnalyticsProfileReport.totalProfileAvgRating = action?.payload?.totalProfileAvgRating || 0

                state.AnalyticsProfileReport.hitsData = action?.payload?.HitsData || []
                state.AnalyticsProfileReport.allFollowersData = action?.payload?.HitsData || []
                // action?.payload?.AllFollowersData || []
            })
            .addCase(fetchAnalyticsProfileReport.rejected, (state, action) => {
                state.AnalyticsStatus = 'failed';
                state.AnalyticsProfileReport = null;
                state.error = action?.error.message;
            })
    },
});

export const { } = analyticsSlice.actions
export default analyticsSlice.reducer

// Export the asynchronous thunks for use in components or other parts of your application