import React, { useEffect, useState } from "react";
import editicon from "../../Images/editicon.png";
import galleryimg from "../../Images/galleryimg.png";
import productdesign from "../../Images/productdesign.png";
import Advertising from "../../Images/Advertising.png";
import DigitalMarketing from "../../Images/DigitalMarketing.png";
import Photography from "../../Images/Photography.png";
import Galleryeditmodal from "../../Modal/Galleryeditmodal";
import Editgalleryproductmodal from "../../Modal/Editgalleryproductmodal";
import Otherproductmodal from "../../Modal/Otherproductmodal";
import defaultuploadimage from "../../Images/uploadimage.png";

import { useSelector } from "react-redux";
const Galleryblmprofile = ({ data = { gallery: [] }, updateForm, setData }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const maxGalleryImages = 4;
  console.log("Dataaa12 : ", data);
  const [galleymodala, setGalleymodala] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [editshoeopen, setEditshoeopen] = useState(false);
  const [editgallerproductmodal, setEditgallerproductmodal] = useState(false);
  const [beautyproductmodal, setBeautyproductmodal] = useState(false);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState()
  const productmodalotherclcik = (e, item) => {
    e.preventDefault()
    console.log("ITEM26", item);

    setSelectedGalleryImage(item)
    setBeautyproductmodal(!beautyproductmodal);
  };
  const gallerclickmodal = () => {
    setGalleymodala(!galleymodala);
  };
  const closemodal = () => {
    setEditgallerproductmodal(false);
  };
  const edishowclickmm = () => {
    setEditshoeopen(!editshoeopen);
  };

  const editgalleryssclick = (item, index) => {
    setSelectedImage(item);
    setSelectedIndex(index)
    setEditgallerproductmodal(true);
  };

  const removeGallery = (indexToRemove) => {
    const updatedGallery = data?.gallery?.filter(
      (_, index) => index !== indexToRemove
    );
    updateForm("gallery", updatedGallery); // Update form with new services array
  };

  console.log("DATA53", data);


  return (
    <>
      <div className="uploadbannerblmdiv">
        <div className="uploadbannerdivnewmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">Gallery</p>
            {
              ((UserProfileID === null || UserProfileID === undefined) || (UserProfileID?._id === data?._id && data?.gallery?.length < maxGalleryImages)) &&
              <div className="editeiconimg" onClick={gallerclickmodal}>
                <img src={editicon} alt="..." />
              </div>
            }

          </div>
          <div className="uploadimagedivflx">
            {Array.isArray(data?.gallery) &&
              data.gallery.map((item, index) => {
                return (
                  <div className="uploadimagebg" key={index}>
                    <div className="uploadimagebgmain">
                      {
                        UserProfileID?._id === data?._id &&
                        <div className="editeiconimghideenalda">
                          {/* <i
                            onClick={() => editgalleryssclick(item, index)}
                            className="fa-solid fa-pen-to-square"
                          ></i> */}

                          <img
                            src={editicon}
                            alt="edit"
                            style={{
                              zIndex: "8",
                              position: "absolute",
                              display: "block",
                              maxWidth: "32px",
                              right: "0",
                              cursor:"pointer",
                            }}
                            onClick={() => editgalleryssclick(item, index)}
                          />

                          {/* <div 
                          className="editeiconimg"
                          style={{
                            border:"solid 1px #ff0000",
                            zIndex:"1000"
                          }}
                            onClick={() => editgalleryssclick(item, index)}
                          >
                            <img src={editicon} alt="..." />
                          </div> */}

                          {/* <i className="fa-solid fa-xmark delete-icon"
                            onClick={() => removeGallery(index)}
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                              color: "red",
                            }}
                          ></i> */}
                        </div>
                      }

                      <div className="cameralogodivimgabslt" style={{
                        width: '100%',
                        maxWidth: '100%',
                        minWidth: '100%',
                        height: '100%',
                        padding: 0,
                      }}>
                        <div className="cameralogodivimg"
                          onClick={(e) => productmodalotherclcik(e, item)}
                          style={{
                            width: "100%",
                            maxWidth: '100%',
                            minWidth: '100%',
                            height: '100%',
                          }}>
                          <img
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                            src={item?.image}
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>

                    <p
                      className="imagetitrtlstrtrxpp"
                      onClick={(e) => productmodalotherclcik(e, item)}
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      {item?.title}
                    </p>
                  </div>
                );
              })}
            {
              UserProfileID?._id === data?._id && data?.gallery?.length < maxGalleryImages && (() => {
                const noGalleryImages = [];
                for (let index = 0; index < (maxGalleryImages - data?.gallery?.length); index++) {
                  noGalleryImages.push(
                    <>
                      <div className="uploadimagebg" key={index}>
                        <div className="uploadimagebgmain">
                          {
                            UserProfileID?._id === data?._id &&
                            <div className="editeiconimghideenalda">
                              {/* <i
                            onClick={gallerclickmodal}
                            className="fa-solid fa-pen-to-square"
                          ></i> */}
                            </div>
                          }
                          <div className="cameralogodivimgabslt" style={{
                            width: '100%',
                            maxWidth: '100%',
                            minWidth: '100%',
                            height: '100%',
                            padding: 0,
                          }}>
                            <div className="cameralogodivimg" style={{
                              width: "100%",
                              maxWidth: '100%',
                              minWidth: '100%',
                              height: '100%',
                            }}>
                              <img
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                                src={defaultuploadimage}
                                alt="Upload image"
                              />
                            </div>
                          </div>
                        </div>
                        <p
                          className="imagetitrtlstrtrxpp"
                        >
                          Title
                        </p>
                      </div>
                    </>

                  );
                }
                return <>{noGalleryImages}</>;
              })()
            }



          </div>
        </div>
      </div>

      {galleymodala && (
        <Galleryeditmodal
          data={data}
          updateForm={updateForm}
          closemodal={setGalleymodala}
          setData={setData}
        />
      )}
      {editgallerproductmodal && (
        <Editgalleryproductmodal
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          data={data}
          updateForm={updateForm}
          closemodal={closemodal}
          setData={setData}
          selectedIndex={selectedIndex}
        />
      )}
      {beautyproductmodal && (
        <Otherproductmodal closemodal={setBeautyproductmodal} data={selectedGalleryImage} />
      )}

    </>
  );
};

export default Galleryblmprofile;
